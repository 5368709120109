export class Partner
{
    constructor(
        public _id?: string,
        public nombre?: string,
        public host?: string,
        public exists?: string,
        public img?: string,
        public owner?: string,
        public colorTheme?: string,
        public direccion?: string,
        public facebook?: string,
        public app?: string,
        public www?: string,
        public tel?: string,
        public publicApiKey?: string,
        public privateApiKey?: string,

        ){}
}
