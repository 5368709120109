import { Action } from '@ngrx/store';
import { ConfiguracionPaginador } from './../../../main/configuracion/models/configuracionPaginador.model';
import { ConfiguracionesApp } from 'app/models/configuracionesApp.model';
import { Configuracion } from 'app/main/configuracion/models/configuracion.model';

export const SET_CONFIGURACION = '[Configuracion] Set Configuracion';
export const QUIT_CONFIGURACION = '[Configuracion] Quit Configuracion';

export const SET_CONFIGURACION_MODEL = '[ConfiguracionModel] Set ConfiguracionModel';
export const QUIT_CONFIGURACION_MODEL = '[ConfiguracionModel] Quit ConfiguracionModel';

export const SET_CONFIGURACION_APP = '[ConfiguracionApp] Set ConfiguracionApp';
export const QUIT_CONFIGURACION_APP = '[ConfiguracionApp] Quit ConfiguracionApp';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetConfiguracionesAppAction implements Action{
  readonly type = SET_CONFIGURACION_APP;

  constructor (public  configuracionesApp : ConfiguracionesApp ){
      //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
  }
}
export class QuitConfiguracionesAppAction implements Action{
  readonly type = QUIT_CONFIGURACION_APP;

  constructor (){
      //localStorage.removeItem('administracionApp');
  }
}

export class SetConfiguracionAction implements Action{
    readonly type = SET_CONFIGURACION;
    constructor (public configuracion:ConfiguracionPaginador){
    }
}
export class QuitConfiguracionAction implements Action{
    readonly type = QUIT_CONFIGURACION;
    constructor (){
    }
}

export class SetConfiguracionModelAction implements Action{
    readonly type = SET_CONFIGURACION_MODEL;
    constructor (public configuracion:Configuracion){
    }
}
export class QuitConfiguracionModelAction implements Action{
    readonly type = QUIT_CONFIGURACION_MODEL;
    constructor (){
    }
}

export type acciones= SetConfiguracionAction  |  QuitConfiguracionAction | QuitConfiguracionesAppAction | SetConfiguracionesAppAction
                      | SetConfiguracionModelAction | QuitConfiguracionModelAction;
