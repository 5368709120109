import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { URL_SERVICIOS } from 'app/config/config';
import { ToastrService } from 'ngx-toastr';
import { PaginadorBusquedaTabla } from 'app/models/paginador.model';
import { ImagenService } from 'app/services/imagen/imagen.service';
import { Contrato } from '../model/contrato.model';
import { Observable } from 'rxjs';
import { ContratoAppService } from '../contratoApp.service';

@Injectable({
  providedIn: 'root'
})
export class ContratoService implements Resolve<any>{
  routeParams: any;
  contrato: Contrato = new Contrato();
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    public store: Store<AppState>,
    public imagenService: ImagenService,
    public toasterService: ToastrService,
    public contratoAppService: ContratoAppService,
  ) {}

  obtenerContrato(paginador: PaginadorBusquedaTabla) {
    // console.log(paginador);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/contratos';
    return this.http.post(url,paginador, {headers});
  }

  obtenerContratoId(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/contrato/' + id;
    return this.http.get(url, {headers});
  }

  eliminarContrato(id: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/contrato/' + id;
    return this.http.delete(url, {headers});
  }

  agregarContrato(contrato: Contrato){
    // console.log(contrato);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    let url = URL_SERVICIOS + '/contrato';
    if (contrato._id){
      // console.log(contrato);
      url += '/' + contrato._id;
      return this.http.put(url, contrato , {headers})
    }
    else{
      return this.http.post(url, contrato , {headers})
    }
  }

  cambiarPDF(archivo: File, id: string){
    this.imagenService.subirArchivo(archivo, 'PDF', id)
      .then((resp: any) => {
        // console.log(resp);
        this.contrato = resp.contrato;
        this.contratoAppService.cambiarContrato(this.contrato);
        this.toasterService.success('El Archivo se ha guardado correctamente ');
      })
      .catch(resp => {
        // console.log(resp);
        this.toasterService.error('El archivo NO se ha guardado correctamente ' , 'Error');
      });
  }

  obtenerPDF(nombre:string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/upload/descargarArchivo/' + nombre;
    //return this.http.get(url,  {headers});

    return this.http.get(url,{
      responseType: 'blob', headers:headers}
     );

  }

  eliminarPfd(contrato : Contrato){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/upload/eliminarArchivo';
    return this.http.post(url, contrato, {headers});
  }

  loginContrato(contrato : Contrato){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/login/Contrato';
    return this.http.post(url, contrato, {headers});
  }

  actualizarPassContrato(contrato:Contrato){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuarioPassContrato/'+contrato._id;
    return this.http.put(url, contrato,{headers})
  }

    /**
   * Get contrato
   *
   * @returns {Promise<any>}
   */
     getContrato(): Promise<any>{
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'authorization': localStorage.getItem('token')
        });
        if(this.routeParams.id === "new"){
          this.contrato = new Contrato();
          resolve(this.contrato);
        }else{
          this.http.get(URL_SERVICIOS + '/contrato/' + this.routeParams.id, {headers})
            .subscribe((response: any) => {
              this.contrato = response.contrato;
              resolve(response.contrato);
            }, reject);
        }
      });
    }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getContrato(),
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
}
