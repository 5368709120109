import { Partner } from './partner.model';
import { PaginadorTabla } from 'app/models/paginador.model';

export class PartnerPaginador
{
    constructor(
        public partner?: Partner,
        public paginador?: PaginadorTabla,
        
        ){}
}
