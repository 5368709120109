import { AppContratoState, ContratoApp } from 'app/models/contratoApp.model';
import * as fromContratoApp from './contratoApp.accions';

const estadoInicial: AppContratoState = new ContratoApp();

export function ContratoReducer(state = estadoInicial, action: fromContratoApp.acciones): AppContratoState {
    switch (action.type) {
        //   FILTRO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_FILTRO_CONTRATO_SERVICIOS:
            let filtroContratos = { ...state };
            filtroContratos.filtro = { ...action.filtro };
            localStorage.setItem('filtroContratosApp', JSON.stringify(filtroContratos));
            return filtroContratos;
        case fromContratoApp.QUIT_FILTRO_CONTRATO_SERVICIOS:
            let QuitFiltroContratoServicios = { ...state };
            QuitFiltroContratoServicios.filtro = null;
            return QuitFiltroContratoServicios;

        //   CONTRATOPAGINADOR ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATO_SERVICIOS_PAGINADOR:
            let nuevoEstado = { ...state };
            nuevoEstado.contrato = { ...action.contratoPaginador };
            localStorage.setItem('contratoApp', JSON.stringify(nuevoEstado));
            return nuevoEstado;
        case fromContratoApp.QUIT_CONTRATO_SERVICIOS_PAGINADOR:
            let quitContratoServiciosPaginador = { ...state };
            quitContratoServiciosPaginador.contrato.paginador = null;
            return quitContratoServiciosPaginador;

        //   CONTRATO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATO_SERVICIOS:
            let nuevoEstadoContrato = { ...state };
            nuevoEstadoContrato.contrato.contrato = { ...action.contrato };
            localStorage.setItem('contratoApp', JSON.stringify(nuevoEstadoContrato));
            return nuevoEstadoContrato;
        case fromContratoApp.QUIT_CONTRATO_SERVICIOS:
            let quitContratoServicios = { ...state };
            quitContratoServicios.contrato.contrato = null;
            return quitContratoServicios;

        //   CONTRATOSAPP ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATO_SERVICIOS_APP:
            let nuevoApp = { ...action.contratoApp };
            localStorage.setItem('contratoApp', JSON.stringify(nuevoApp));
            return nuevoApp
        case fromContratoApp.QUIT_CONTRATO_SERVICIOS_APP:
            return new ContratoApp();
        default:
            return state;
    }
}
