import { PlanUsuarioTarjeta } from './../../../main/tarjetas/models/planUsuarioTarjeta.model';
import { Tarjeta } from './../../../main/tarjetas/models/tarjetas.model';
import { PlanTarjeta } from './../../../main/tarjetas/models/planTarjeta.model';
import { PlanUsuarioTarjetaPaginador } from './../../../main/tarjetas/models/planUsuarioTarjetaPaginador.model';
import { Action } from '@ngrx/store';
import { PlanTarjetasPaginador } from 'app/main/tarjetas/models/planTarjetaPaginador.model';
import { Filtro } from 'app/models/filtro.model';
import { TarjetasApp } from 'app/models/tarjetasApp.model';
import { TarjetasPaginador } from './../../../main/tarjetas/models/tarjetasPaginador.model';

export const SET_TARJETA = '[Tarjeta] Set Tarjeta';
export const QUIT_TARJETA = '[Tarjeta] Quit Tarjeta';

export const SET_TARJETA_MODEL = '[TarjetaModel] Set TarjetaModel';
export const QUIT_TARJETA_MODEL = '[TarjetaModel] Quit TarjetaModel';

export const SET_PLANTARJETA = '[PlanTarjeta] Set PlanTarjeta';
export const QUIT_PLANTARJETA = '[PlanTarjeta] Quit PlanTarjeta';

export const SET_PLANUSUARIOTARJETA = '[PlanUsuarioTarjeta] Set PlanUsuarioTarjeta';
export const QUIT_PLANUSUARIOTARJETA = '[PlanUsuarioTarjeta] Quit PlanUsuarioTarjeta';

export const SET_PLANTARJETA_MODEL = '[PlanTarjetaModel] Set PlanTarjetaModel';
export const QUIT_PLANTARJETA_MODEL = '[PlanTarjetaModel] Quit PlanTarjetaModel';

export const SET_PLANUSUARIOTARJETA_MODEL = '[PlanUsuarioTarjetaModel] Set PlanUsuarioTarjetaModel';
export const QUIT_PLANUSUARIOTARJETA_MODEL = '[PlanUsuarioTarjetaModel] Quit PlanUsuarioTarjetaModel';

export const SET_TARJETA_APP = '[TarjetaApp] Set TarjetaApp';
export const QUIT_TARJETA_APP = '[TarjetaApp] Quit TarjetaApp';

export const SET_TARJETACHECKOUT = '[TarjetaCheckout] Set TarjetaCheckout';
export const QUIT_TARJETACHECKOUT = '[TarjetaCheckout] Quit TarjetaCheckout';

export const SET_TARJETAFILTROS = '[TarjetasFiltro] Set TarjetasFiltro';
export const QUIT_TARJETAFILTROS = '[TarjetasFiltro] Quit TarjetasFiltro';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetTarjetaAppAction implements Action{
  readonly type = SET_TARJETA_APP;
  constructor (public  tarjetasApp : TarjetasApp ){}
}
export class QuitTarjetaAppAction implements Action{
  readonly type = QUIT_TARJETA_APP;
  constructor (){}
}

// TARJETASPAGINADOR
export class SetTarjetaAction implements Action{
    readonly type = SET_TARJETA;
    constructor (public tarjetasApp: TarjetasPaginador){}
}
export class QuitTarjetaAction implements Action{
    readonly type = QUIT_TARJETA;
    constructor (){}
}

// TARJETASMODEL
export class SetTarjetaModelAction implements Action{
    readonly type = SET_TARJETA_MODEL;
    constructor (public tarjeta: Tarjeta){}
}
export class QuitTarjetaModelAction implements Action{
    readonly type = QUIT_TARJETA_MODEL;
    constructor (){}
}

// PLANES TARJETAS PAGINADOR
export class SetPlanTarjetaAction implements Action{
  readonly type = SET_PLANTARJETA;
  constructor (public planTarjetas: PlanTarjetasPaginador){}
}
export class QuitPlanTarjetaAction implements Action{
  readonly type = QUIT_PLANTARJETA;
  constructor (){}
}

// PLANES TARJETAS MODEL
export class SetPlanTarjetaModelAction implements Action{
  readonly type = SET_PLANTARJETA_MODEL;
  constructor (public planTarjeta: PlanTarjeta){}
}
export class QuitPlanTarjetaModelAction implements Action{
  readonly type = QUIT_PLANTARJETA_MODEL;
  constructor (){}
}

// PLANES USUARIOS TARJETAS
export class SetPlanUsuarioTarjetaAction implements Action{
  readonly type = SET_PLANUSUARIOTARJETA;
  constructor (public planUsuarioTarjetas: PlanUsuarioTarjetaPaginador){}}
export class QuitPlanUsuarioTarjetaAction implements Action{
  readonly type = QUIT_PLANUSUARIOTARJETA;
  constructor (){}
}

// PLANES USUARIOS TARJETAS MODEL
export class SetPlanUsuarioTarjetaModelAction implements Action{
  readonly type = SET_PLANUSUARIOTARJETA_MODEL;
  constructor (public planUsuarioTarjeta: PlanUsuarioTarjeta){}}
export class QuitPlanUsuarioTarjetaModelAction implements Action{
  readonly type = QUIT_PLANUSUARIOTARJETA_MODEL;
  constructor (){}
}

// CHECKOUT TARJETAS
export class SetTarjetaCheckoutAction implements Action{
  readonly type = SET_TARJETACHECKOUT;
  constructor (public planTarjetas: PlanTarjeta){}
}
export class QuitTarjetaCheckoutAction implements Action{
  readonly type = QUIT_TARJETACHECKOUT;
  constructor (){}
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroTarjetaAction implements Action{
  readonly type = SET_TARJETAFILTROS;
  constructor (public filtro : Filtro){}
}
export class QuitFiltroTarjetaAction implements Action{
  readonly type = QUIT_TARJETAFILTROS;
  constructor (){}
}

export type acciones = SetTarjetaAction  |  QuitTarjetaAction | SetTarjetaAppAction |
                       QuitTarjetaAppAction | SetPlanTarjetaAction | QuitPlanTarjetaAction |
                       SetFiltroTarjetaAction | QuitFiltroTarjetaAction | SetPlanUsuarioTarjetaAction |
                       QuitPlanUsuarioTarjetaAction | SetTarjetaCheckoutAction | QuitTarjetaCheckoutAction |
                       SetTarjetaModelAction | QuitTarjetaModelAction | SetPlanTarjetaModelAction |
                       QuitPlanTarjetaModelAction | SetPlanUsuarioTarjetaModelAction | QuitPlanUsuarioTarjetaModelAction;
