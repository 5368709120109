import * as fromControlEscolarApp from './controlEscolarApp.accions';
import { ControlEscolarAppState, ControlEscolarApp } from 'app/models/controlEscolarApp.model';


const estadoInicial: ControlEscolarAppState = new ControlEscolarApp();


export function ControlEscolarReducer (state = estadoInicial,action:fromControlEscolarApp.acciones):ControlEscolarAppState{
    switch(action.type){
        //   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
       case fromControlEscolarApp.SET_CONTROL_ESCOLAR:
            let controlEscolarApp = { ...action.controlEscolarApp };
            localStorage.setItem('controlEscolarApp', JSON.stringify(controlEscolarApp));
            return controlEscolarApp
        case fromControlEscolarApp.QUIT_CONTROL_ESCOLAR:
            return new ControlEscolarApp();
       //   ESTUDIANTE ---------------------------------------------------------------------------------------------------------
      case fromControlEscolarApp.SET_CE_ESTUDIANTES:
            let nuevoEstadoEstudiante = { ...state };
            nuevoEstadoEstudiante.userEstudiante = { ...action.userPaginador };
            localStorage.setItem('controlEscolarApp', JSON.stringify(nuevoEstadoEstudiante));
            return nuevoEstadoEstudiante;
        case fromControlEscolarApp.QUIT_CE_ESTUDIANTES:
            localStorage.removeItem('estudiante');
            let quitEstudiante = { ...state };
            quitEstudiante.userEstudiante = null;
            return quitEstudiante;
          //   ESTUDIANTE ---------------------------------------------------------------------------------------------------------
        case fromControlEscolarApp.SET_CE_MAESTROS:
            let nuevoEstadoMaestro = { ...state };
            nuevoEstadoMaestro.userMaestro = { ...action.userPaginador };
            localStorage.setItem('controlEscolarApp', JSON.stringify(nuevoEstadoMaestro));
            return nuevoEstadoMaestro;
        case fromControlEscolarApp.QUIT_CE_MAESTROS:
            localStorage.removeItem('maestro');
            let quitMaestro = { ...state };
            quitMaestro.userMaestro = null;
            return quitMaestro;
        //   GRUPO ---------------------------------------------------------------------------------------------------------
        case fromControlEscolarApp.SET_CE_GRUPOS:
            let nuevoEstadoGrupo = { ...state };
            nuevoEstadoGrupo.grupo = { ...action.grupoPaginador };
            localStorage.setItem('controlEscolarApp', JSON.stringify(nuevoEstadoGrupo));
            return nuevoEstadoGrupo;
        case fromControlEscolarApp.QUIT_CE_GRUPOS:
            localStorage.removeItem('grupo');
            let quitGrupo = { ...state };
            quitGrupo.grupo = null;
            return quitGrupo;
        //   NOTIFICACION ---------------------------------------------------------------------------------------------------------
        case fromControlEscolarApp.SET_CE_NOTIFICACIONES:
            let nuevoEstadoNotificacion = { ...state };
            nuevoEstadoNotificacion.notificacion = { ...action.notificacionPaginador };
            localStorage.setItem('controlEscolarApp', JSON.stringify(nuevoEstadoNotificacion));
            return nuevoEstadoNotificacion;
        case fromControlEscolarApp.QUIT_CE_NOTIFICACIONES:
            localStorage.removeItem('notificacion');
            let quitNotificacion = { ...state };
            quitNotificacion.notificacion = null;
            return quitNotificacion;
         //   PROGRAMA ---------------------------------------------------------------------------------------------------------
         case fromControlEscolarApp.SET_CE_PROGRAMAS:
            let nuevoEstadoPrograma = { ...state };
            nuevoEstadoPrograma.programa = { ...action.programaPaginador };
            localStorage.setItem('controlEscolarApp', JSON.stringify(nuevoEstadoPrograma));
            return nuevoEstadoPrograma;
        case fromControlEscolarApp.QUIT_CE_PROGRAMAS:
            localStorage.removeItem('programa');
            let quitPrograma = { ...state };
            quitPrograma.programa = null;
            return quitPrograma;
        //   C ---------------------------------------------------------------------------------------------------------
       /*case fromProductoInversionApp.SET_CONTRATO_INVERSION:
        let nuevoEstadoContrato = { ...state };
            nuevoEstadoContrato.contratoInversion = { ...action.contratoInversion };
            localStorage.setItem('productoInversionApp', JSON.stringify(nuevoEstadoContrato));
            return nuevoEstadoContrato;
        case fromProductoInversionApp.QUIT_CONTRATO_INVERSION:
            localStorage.removeItem('partner');
        return {
            contratoInversion: null,
        };*/
         //   FILTRO ---------------------------------------------------------------------------------------------------------
         case fromControlEscolarApp.SET_CONTROL_ESCOLAR_FILTROS:
            let filtroProductosFiltro = { ...state };
            filtroProductosFiltro.filtro = { ...action.filtro };
            localStorage.setItem('controlEscolarApp', JSON.stringify(filtroProductosFiltro));
            return filtroProductosFiltro;
        case fromControlEscolarApp.QUIT_CONTROL_ESCOLAR_FILTROS:
            let quitFiltro = { ...state };
            quitFiltro.filtro = null;
            return quitFiltro;
        default:
            return state;
    }
}
