import { Contrato } from './model/contrato.model';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ContratoApp } from 'app/models/contratoApp.model';
import { Filtro } from 'app/models/filtro.model';
import {  SetContratoServiciosPaginadorAction,
          QuitContratoServiciosPaginadorAction,
          SetContratoServiciosAction,
          QuitContratoServiciosAction,
          SetContratoServiciosAppAction,
          QuitContratoServiciosAppAction,
          SetFiltroContratoServiciosAction,
          QuitFiltroContratoServiciosAction
} from 'app/services/reducers/contratoApp/contratoApp.accions';
import { Subscription, Observable } from 'rxjs';
import { ContratoPaginador } from './model/contratoPaginador.model';

@Injectable({
  providedIn: 'root'
})
export class ContratoAppService implements Resolve<any> {

  contratoApp : ContratoApp = new ContratoApp();
  contratoSubscriber : Subscription;
  filtro : Filtro = new Filtro();

  constructor(
    private store: Store<AppState> ) {}

  crearSubcriber (){
    return this.store.select('contratoApp');
  }

  cambiarContratoPaginador ( contratoPaginador: ContratoPaginador ){
      this.store.dispatch(new SetContratoServiciosPaginadorAction(contratoPaginador));
  }

  quitarContratoPaginador (){
    this.store.dispatch(new QuitContratoServiciosPaginadorAction());
  }

  cambiarContrato( contrato: Contrato ){
      this.store.dispatch(new SetContratoServiciosAction(contrato));
  }

  quitarContrato (){
    this.store.dispatch(new QuitContratoServiciosAction());
  }

  cambiarFiltro ( filtro : Filtro ){
    this.store.dispatch(new SetFiltroContratoServiciosAction(filtro));
  }

  quitarFiltro(){
    this.store.dispatch(new QuitFiltroContratoServiciosAction());
  }

  setContratonApp ( contratonApp : ContratoApp ){
    this.store.dispatch(new SetContratoServiciosAppAction(contratonApp));
  }

  quitarContratonApp (){
    this.store.dispatch(new QuitContratoServiciosAppAction());
  }

  eliminarStore(){
        this.contratoSubscriber.unsubscribe();
  }

  cargarStorage(): Promise<any> {

    return new Promise ((resolve, reject ) =>{
      let contratoApp: ContratoApp = new ContratoApp();
      if (localStorage.getItem('contratoApp')) {
        contratoApp = JSON.parse(localStorage.getItem('contratoApp'));
      }
      this.setContratonApp(contratoApp);
      resolve(contratoApp);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise<void>((resolve, reject) => {
          Promise.all([
              this.cargarStorage(), //  carga el ultimo estado del modulo de contratos
          ]).then(
              ([contratoApp]) => {
                  this.contratoApp = contratoApp;
                  resolve();
              },
              reject
          );
      });
  }

}
