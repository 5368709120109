import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UsuarioProfileService} from '../../main/administracion/usuarios/services/usuarioProfile.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardsGuard implements  CanActivate{
  constructor(
    public router: Router,
    public _usuarioProfileService: UsuarioProfileService) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._usuarioProfileService.estaLogueado()
      .pipe(map((response: { authenticated: boolean, token: string}) => {
      if (response.authenticated) {
        localStorage.setItem('token', response.token)
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }), catchError((error) => {
      this.router.navigate(['/login']);
      return of(false);
    }));
  }
}
