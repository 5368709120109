import { Tarjeta } from './models/tarjetas.model';
import { PlanTarjeta } from './models/planTarjeta.model';
import { Filtro } from './../../models/filtro.model';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { TarjetasApp } from 'app/models/tarjetasApp.model';
import { Observable, Subscription } from 'rxjs';
import {
  SetTarjetaAction,
  QuitTarjetaAction,
  SetTarjetaAppAction,
  QuitTarjetaAppAction,
  SetPlanTarjetaAction,
  QuitPlanTarjetaAction,
  SetFiltroTarjetaAction,
  QuitFiltroTarjetaAction,
  SetPlanUsuarioTarjetaAction,
  QuitPlanUsuarioTarjetaAction,
  SetTarjetaCheckoutAction,
  QuitTarjetaCheckoutAction,
  SetTarjetaModelAction,
  QuitTarjetaModelAction,
  SetPlanTarjetaModelAction,
  QuitPlanTarjetaModelAction,
  SetPlanUsuarioTarjetaModelAction,
  QuitPlanUsuarioTarjetaModelAction
} from '../.././services/reducers/tarjetas/tarjeta.accions';
import { PlanTarjetasPaginador } from './models/planTarjetaPaginador.model';
import { TarjetasPaginador } from './models/tarjetasPaginador.model';
import { PlanUsuarioTarjetaPaginador } from './models/planUsuarioTarjetaPaginador.model';
import { PlanUsuarioTarjeta } from './models/planUsuarioTarjeta.model';

@Injectable({
  providedIn: 'root'
})
export class TarjetasAppService {
  tarjetasApp : TarjetasApp = new TarjetasApp();
  tarjetasSubscriber : Subscription;

  constructor(
    public store: Store<AppState>,
  ) { }


  crearSubcriber (){
    return this.store.select('tarjetasApp');
  }

  cambiarTarjetaApp ( tarjetaPaginador: TarjetasPaginador ){
    this.store.dispatch(new SetTarjetaAction(tarjetaPaginador));
  }

  quitarTarjetaApp (){
    this.store.dispatch(new QuitTarjetaAction());
  }

  cambiarPlanTarjeta ( plantarjetaPaginador: PlanTarjetasPaginador ){
    this.store.dispatch(new SetPlanTarjetaAction(plantarjetaPaginador));
  }

  quitarPlanTarjeta (){
    this.store.dispatch(new QuitPlanTarjetaAction());
  }

  cambiarPlanUsuarioTarjeta ( planUsuarioTarjetaPaginador: PlanUsuarioTarjetaPaginador ){
    this.store.dispatch(new SetPlanUsuarioTarjetaAction(planUsuarioTarjetaPaginador));
  }

  quitarPlanUsuarioTarjeta (){
    this.store.dispatch(new QuitPlanUsuarioTarjetaAction());
  }

  cambiarPlanTarjetaCheckout ( planTarjeta: PlanTarjeta ){
    this.store.dispatch(new SetTarjetaCheckoutAction(planTarjeta));
  }

  quitarPlanTarjetaCheckout (){
    this.store.dispatch(new QuitTarjetaCheckoutAction());
  }

  cambiarFiltro ( filtro: Filtro ){
    this.store.dispatch(new SetFiltroTarjetaAction(filtro));
  }

  quitarFiltro (){
    this.store.dispatch(new QuitFiltroTarjetaAction());
  }
  
  setTarjetasApp ( tarjetasApp : TarjetasApp ){
    this.store.dispatch(new SetTarjetaAppAction(tarjetasApp));
  }
  
  quitTarjetasApp (){
    this.store.dispatch(new QuitTarjetaAppAction());
  }
  
  cambiarTarjetaModel ( tarjeta : Tarjeta ){
    this.store.dispatch(new SetTarjetaModelAction(tarjeta));
  }
  
  quitarTarjetaModel (){
    this.store.dispatch(new QuitTarjetaModelAction());
  }
  
  cambiarPlanTarjetaModel ( planTarjeta : PlanTarjeta ){
    this.store.dispatch(new SetPlanTarjetaModelAction(planTarjeta));
  }
  
  quitarPlanTarjetaModel (){
    this.store.dispatch(new QuitPlanTarjetaModelAction());
  }
  
  cambiarPlanUsuarioTarjetaModel ( planUsuarioTarjeta : PlanUsuarioTarjeta ){
    this.store.dispatch(new SetPlanUsuarioTarjetaModelAction(planUsuarioTarjeta));
  }
  
  quitarPlanUsuarioTarjetaModel (){
    this.store.dispatch(new QuitPlanUsuarioTarjetaModelAction());
  }

  eliminarStore(){
    this.tarjetasSubscriber.unsubscribe();
  }

  cargarStorage(): Promise<any> {

    return new Promise ((resolve, reject ) =>{
      let tarjetasAppp: TarjetasApp = new TarjetasApp();
      if (localStorage.getItem('tarjetasApp')) {
        tarjetasAppp = JSON.parse(localStorage.getItem('tarjetasApp'));
        //console.log('tarjetasApp:' + tarjetasAppp);
      }

      //console.log(tarjetasAppp);
      this.setTarjetasApp(tarjetasAppp);
      resolve(tarjetasAppp);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
      return new Promise<void>((resolve, reject) => {
          Promise.all([
              this.cargarStorage(),
          ]).then(
              ([tarjetasApp]) => {
                  this.tarjetasApp = tarjetasApp;
                  resolve();
              },
              reject
          );
      });
  }

}
