import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {URL_SERVICIOS} from 'app/config/config';
import {Usuario} from 'app/main/administracion/usuarios/models/usuario.model';
import {map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ImagenService} from '../../../../services/imagen/imagen.service';
import {ToastrService} from 'ngx-toastr';
import {Empresa} from 'app/main/administracion/empresa/models/empresa.model';
import {Partner} from 'app/main/administracion/partner/models/partner.model';
import {Filtro} from 'app/models/filtro.model';
import {PaginadorTabla} from 'app/models/paginador.model';
import {Busqueda} from 'app/models/busqeuda.model';
import {Observable} from 'rxjs';
import {Aplicacion} from 'app/models/aplicacion.model';
import {AppService} from 'app/app.service';
import {AdministracionAppService} from '../../administracion.service';
import {UsuarioProfileService} from "./usuarioProfile.service";
import { PartnerService } from '../../partner/services/partner.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService implements Resolve<any> {

  routeParams: any;

  usuario: Usuario;
  partner: Partner;
  usuarioProfile: Usuario;
  token: string;
  menu: any = [];
  empresa: Empresa;
  filtro: Filtro;
  partners: Partner [];
  busqueda: Busqueda;

  app: Aplicacion = new Aplicacion();


  constructor(
    public http: HttpClient,
    public router: Router,
    public imagenService: ImagenService,
    public toasterService: ToastrService,
    public appService: AppService,
    public usuarioProfileService: UsuarioProfileService,
    public administracionAppService: AdministracionAppService,
    public partnerService: PartnerService,
  ) {}

  obtenerUsuarioSolicitudes() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuarioSolicitudes/';
    return this.http.get(url, {headers});
  }

  obtenerUsuarioConPaginacion(paginador: PaginadorTabla) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    //console.log(paginador);
    let url = URL_SERVICIOS + '/usuarios/';
    return this.http.post(url, paginador, {headers});
  }

  obtenerUsuarioSinPaginacion(paginador: PaginadorTabla) {
    //console.log(paginador);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuariosPorRole/';
    return this.http.post(url, paginador, {headers});
  }

  obtenerClientesVendedores(paginador: PaginadorTabla) {
    //console.log(paginador);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuariosClientesVendedores/';
    return this.http.post(url, paginador, {headers});
  }

  obtenerClientes() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuariosClientes/';
    return this.http.get(url, {headers});
  }

  crearUsuario(usuario: Usuario) {
    let url = URL_SERVICIOS + '/usuario';
    return this.http.post(url, usuario).pipe(map((resp: any) => {
      return resp.usuario;
    }));
  }

  actualizarPass(usuario: Usuario) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuarioPass/' + usuario._id;
    return this.http.put(url, usuario, {headers});
  }

  ActualizarUsuario(usuario: Usuario) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuario/' + usuario._id;
    return this.http.put(url, usuario, {headers});
  }

  cambiarImagen(archivo: File, id: string, ) {
    return this.imagenService.subirArchivo(archivo, 'usuarios', id).then((data: any) => {
      this.toasterService.success('Transaccion Correcta', 'La Imagen se ha Guardado Correctamente ' + '');
      //console.log('1****>' + this.usuarioProfileService.usuario?._id);
      //console.log('2****>' + data.usuario._id);
      //console.log('3****>' + id);

      if(data.usuario?._id === this.usuarioProfileService.usuario?._id) {
        this.appService.cambiarUsuario(data.usuario);
      }
    });
  }

  eliminarUsuario(id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuario/' + id;
    return this.http.delete(url, {headers});
  }

  obtenerUsuarioId(id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuario/' + id;
    return this.http.get(url, {headers});
  }

  obtenerUsuarioTarjeta(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuarioTarjeta';
    return this.http.get(url,{headers});
  }

  obtenerUsuarioPorEmpresaSinPaginacion(id:string){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuariosEmpresa/'+id;
    return this.http.get(url,{headers});
  }

  obtenerUsuariosPorPartner(id:string){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuariosPartner/'+id;
    return this.http.get(url,{headers});
  }

  obtenerUsuarioEmpresaGurpo(id: string, id2: string){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuariosEmpresaGrupo/'+id+'/'+id2;
    return this.http.get(url,{headers});
  }

  obtenerUsuarioGurpoSinPaginacion(id: string){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    });
    let url= URL_SERVICIOS + '/usuariosGrupo/'+id;
    return this.http.get(url,{headers});
  }

  guardarUsario(usuario: Usuario) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });
    let url = URL_SERVICIOS + '/usuario';
    if (usuario._id) {
      url += '/' + usuario._id;
      return this.http.put(url, usuario, {headers});
    } else {
      return this.http.post(url, usuario, {headers});
    }
  }


  renuevaToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/login/renuevatoken';
    this.http.get(url, {headers}).subscribe((resp: any) => {
      //console.log(resp);
    });
  }

  logoutService() {
    let url = URL_SERVICIOS + '/login/logout';
    return this.http.get(url).subscribe((resp: any) => {
      //console.log(resp);
    });
  }

  /**
   * Get contrato
   *
   * @returns {Promise<any>}
   */
  getUsuario(): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      if ('new' === this.routeParams.idUsuario) {
        resolve(this.usuario = new Usuario());
      }

      this.http.get(URL_SERVICIOS + '/usuario/' + this.routeParams.idUsuario, {headers})
        .subscribe((response: any) => {
          //console.log(response);
          this.usuario = response.usuario;
          this.appService.cambiarModeloEditar(response.usuario);
          resolve(response.contrato);
        }, reject);
    });
  }

  /**
   * Get Complejos
   *
   * @returns {Promise<any>}
   */
  getPartners(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      this.http.get(URL_SERVICIOS + '/partner', {headers})
        .subscribe((response: any) => {
          //console.log(response.partner);
          this.partners = response.partner;

          resolve(response.partner);
        }, reject);
    });
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    //console.log(this.routeParams);
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getUsuario(),
        this.getPartners(),
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

}
