import { TarjetasApp, TarjetaState } from 'app/models/tarjetasApp.model';
import * as fromTarjeta from './tarjeta.accions';

const estadoInicial: TarjetasApp = new TarjetasApp();

export function TarjetaReducer(state = estadoInicial,action:fromTarjeta.acciones):TarjetaState{
    switch(action.type){

      //   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_TARJETA_APP:
        let tarjetaApp = { ...action.tarjetasApp };
        localStorage.setItem('tarjetasApp', JSON.stringify(tarjetaApp));
        return tarjetaApp
      case fromTarjeta.QUIT_TARJETA_APP:
          return new TarjetasApp();

      //   TARJETAS PAGINADOR ---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_TARJETA:
        let nuevoEstadoTarjeta = { ...state };
        nuevoEstadoTarjeta.tarjeta = { ...action.tarjetasApp };
        localStorage.setItem('tarjetasApp', JSON.stringify(nuevoEstadoTarjeta));
        return nuevoEstadoTarjeta;
      case fromTarjeta.QUIT_TARJETA:
        localStorage.removeItem('tarjetasApp');
        let quitTarjeta = { ...state };
        quitTarjeta.tarjeta.paginador = null;
        return quitTarjeta;

      //   TARJETAS MODEL---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_TARJETA_MODEL:
        let nuevoEstadoTarjetaModel = { ...state };
        nuevoEstadoTarjetaModel.tarjeta.tarjeta = { ...action.tarjeta };
        localStorage.setItem('tarjetasAppModel', JSON.stringify(nuevoEstadoTarjetaModel));
        return nuevoEstadoTarjetaModel;
      case fromTarjeta.QUIT_TARJETA_MODEL:
        localStorage.removeItem('tarjetasAppModel');
        let quitTarjetaModel = { ...state };
        quitTarjetaModel.tarjeta.tarjeta = null;
        return quitTarjetaModel;

      //   PLANTARJETAS PAGINADOR---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_PLANTARJETA:
        let nuevoEstadoPlanTarjeta = { ...state };
        nuevoEstadoPlanTarjeta.planTarjeta = { ...action.planTarjetas };
        localStorage.setItem('tarjetasApp', JSON.stringify(nuevoEstadoPlanTarjeta));
        return nuevoEstadoPlanTarjeta;
      case fromTarjeta.QUIT_PLANTARJETA:
        localStorage.removeItem('tarjetasApp');
        let quitPlanTarjeta = { ...state };
        quitPlanTarjeta.planTarjeta.paginador = null;
        return quitPlanTarjeta;

      //   PLANTARJETAS MODEL---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_PLANTARJETA_MODEL:
        let nuevoEstadoPlanTarjetaModel = { ...state };
        nuevoEstadoPlanTarjetaModel.planTarjeta.planTarjeta = { ...action.planTarjeta };
        localStorage.setItem('tarjetasAppModel', JSON.stringify(nuevoEstadoPlanTarjetaModel));
        return nuevoEstadoPlanTarjetaModel;
      case fromTarjeta.QUIT_PLANTARJETA_MODEL:
        localStorage.removeItem('tarjetasAppModel');
        let quitPlanTarjetaModel = { ...state };
        quitPlanTarjetaModel.planTarjeta.planTarjeta = null;
        return quitPlanTarjetaModel;

      //   PLANUSUARIOTARJETAS PAGINADOR---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_PLANUSUARIOTARJETA:
        let nuevoEstadoPlanUsuarioTarjeta = { ...state };
        nuevoEstadoPlanUsuarioTarjeta.planUsuarioTarjeta = { ...action.planUsuarioTarjetas };
        localStorage.setItem('tarjetasApp', JSON.stringify(nuevoEstadoPlanUsuarioTarjeta));
        return nuevoEstadoPlanUsuarioTarjeta;
      case fromTarjeta.QUIT_PLANUSUARIOTARJETA:
        localStorage.removeItem('tarjetasApp');
        let quitPlanUsuarioTarjeta = { ...state };
        quitPlanUsuarioTarjeta.planUsuarioTarjeta.paginador = null;
        return quitPlanUsuarioTarjeta;

      //   PLANUSUARIOTARJETAS MODEL---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_PLANUSUARIOTARJETA_MODEL:
        let nuevoEstadoPlanUsuarioTarjetaModel = { ...state };
        nuevoEstadoPlanUsuarioTarjetaModel.planUsuarioTarjeta.planUsuarioTarjeta = { ...action.planUsuarioTarjeta };
        localStorage.setItem('tarjetasAppModel', JSON.stringify(nuevoEstadoPlanUsuarioTarjetaModel));
        return nuevoEstadoPlanUsuarioTarjetaModel;
      case fromTarjeta.QUIT_PLANUSUARIOTARJETA_MODEL:
        localStorage.removeItem('tarjetasAppModel');
        let quitPlanUsuarioTarjetaModel = { ...state };
        quitPlanUsuarioTarjetaModel.planUsuarioTarjeta.planUsuarioTarjeta = null;
        return quitPlanUsuarioTarjetaModel;

      //   TARJETASCHECKOUT ---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_TARJETACHECKOUT:
        let nuevoEstadoTarjetaCheckout = { ...state };
        nuevoEstadoTarjetaCheckout.tarjetaCheckout = { ...action.planTarjetas };
        localStorage.setItem('tarjetasApp', JSON.stringify(nuevoEstadoTarjetaCheckout));
        return nuevoEstadoTarjetaCheckout;
      case fromTarjeta.QUIT_TARJETACHECKOUT:
        localStorage.removeItem('tarjetasApp');
        let quitCheckout = { ...state };
        quitCheckout.tarjetaCheckout = null;
        return quitCheckout;

      //   FILTRO ---------------------------------------------------------------------------------------------------------
      case fromTarjeta.SET_TARJETAFILTROS:
        let nuevoEstadoFiltro = { ...state };
        nuevoEstadoFiltro.filtro = { ...action.filtro };
        localStorage.setItem('tarjetasApp', JSON.stringify(nuevoEstadoFiltro));
        return nuevoEstadoFiltro;
      case fromTarjeta.QUIT_TARJETAFILTROS:
        localStorage.removeItem('tarjetasApp');
        let quitFiltro = { ...state };
        quitFiltro.filtro = null;
        return quitFiltro;

      default:
        return state;
    }
}
