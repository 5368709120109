import {Action} from '@ngrx/store';
import { Filtro } from 'app/models/filtro.model';
import { ProductoInversionPaginador } from 'app/main/inversiones/product-investment/models/product-investmentPaginador.model';
import { ProductoInversionApp } from 'app/models/productosInversion.model';
import { ContratoInversionPaginador } from 'app/main/inversiones/contrato/model/contratoInversionPagina.model';
import { LeadPaginador } from '../../../main/inversiones/leads/models/leadPaginador.model';
import { InteresVariableInversion } from 'app/main/inversiones/interesVariable/models/interesVariable.model';
import { CommissionInversionPaginador } from 'app/main/inversiones/commission/models/commisionInversionPaginador';
import { ProductInvestment } from 'app/main/inversiones/product-investment/models/product-investment.model';
import { ContratoInversion } from 'app/main/inversiones/contrato/model/contratoInversion.model';
import { Lead } from 'app/main/inversiones/leads/models/lead.model';
import { CommissionInversion } from 'app/main/inversiones/commission/models/commisionInversion.model';

export const SET_PRODUCTO_INVERSION = '[Producto_Inversion] Set Producto_Inversion';
export const QUIT_PRODUCTO_INVERSION  = '[Producto_Inversion] Quit Producto_Inversion';

export const SET_PRODUCTO_INVERSION_MODEL = '[Producto_Inversion_Model] Set Producto_Inversion_Model';
export const QUIT_PRODUCTO_INVERSION_MODEL  = '[Producto_Inversion_Model] Quit Producto_Inversion_Model';

export const SET_CONTRATO_INVERSION = '[Contrato_Inversion] Set Contrato_Inversion';
export const QUIT_CONTRATO_INVERSION  = '[Contrato_Inversion] Quit Contrato_Inversion';

export const SET_CONTRATO_INVERSION_MODEL = '[Contrato_Inversion_Model] Set Contrato_Inversion_Model';
export const QUIT_CONTRATO_INVERSION_MODEL  = '[Contrato_Inversion_Model] Quit Contrato_Inversion_Model';

export const SET_LEAD_INVERSION = '[Lead_Inversion] Set Lead_Inversion';
export const QUIT_LEAD_INVERSION  = '[Lead_Inversion] Quit Lead_Inversion';

export const SET_LEAD_INVERSION_MODEL = '[Lead_Inversion_Model] Set Lead_Inversion_Model';
export const QUIT_LEAD_INVERSION_MODEL  = '[Lead_Inversion_Model] Quit Lead_Inversion_Model';

export const SET_COMMISSION_INVERSION_T1 = '[Commission_Inversion_T1] Set Commission_Inversion_T1';
export const QUIT_COMMISSION_INVERSION_T1  = '[Commission_Inversion_T1] Quit Commission_Inversion_T1';

export const SET_COMMISSION_INVERSION_T1_MODEL = '[Commission_Inversion_T1_Model] Set Commission_Inversion_T1_Model';
export const QUIT_COMMISSION_INVERSION_T1_MODEL  = '[Commission_Inversion_T1_Model] Quit Commission_Inversion_T1_Model';

export const SET_COMMISSION_INVERSION_T2 = '[Commission_Inversion_T2] Set Commission_Inversion_T2';
export const QUIT_COMMISSION_INVERSION_T2  = '[Commission_Inversion_T2] Quit Commission_Inversion_T2';

export const SET_COMMISSION_INVERSION_T2_MODEL = '[Commission_Inversion_T2_Model] Set Commission_Inversion_T2_Model';
export const QUIT_COMMISSION_INVERSION_T2_MODEL  = '[Commission_Inversion_T2_Model] Quit Commission_Inversion_T2_Model';

export const SET_COMMISSION_INVERSION_T3 = '[Commission_Inversion_T3] Set Commission_Inversion_T3';
export const QUIT_COMMISSION_INVERSION_T3  = '[Commission_Inversion_T3] Quit Commission_Inversion_T3';

export const SET_COMMISSION_INVERSION_T3_MODEL = '[Commission_Inversion_T3_Model] Set Commission_Inversion_T3_Model';
export const QUIT_COMMISSION_INVERSION_T3_MODEL  = '[Commission_Inversion_T3_Model] Quit Commission_Inversion_T3_Model';

export const SET_COMMISSION_INVERSION_T4 = '[Commission_Inversion_T4] Set Commission_Inversion_T4';
export const QUIT_COMMISSION_INVERSION_T4  = '[Commission_Inversion_T4] Quit Commission_Inversion_T4';

export const SET_COMMISSION_INVERSION_T4_MODEL = '[Commission_Inversion_T4_Model] Set Commission_Inversion_T4_Model';
export const QUIT_COMMISSION_INVERSION_T4_MODEL  = '[Commission_Inversion_T4_Model] Quit Commission_Inversion_T4_Model';

export const SET_INTERESVARIABLE_INVERSION = '[InteresVariable_Inversion] Set InteresVariable_Inversion';
export const QUIT_INTERESVARIABLE_INVERSION  = '[InteresVariable_Inversion] Quit InteresVariable_Inversion';

export const SET_PRODUCTOAPP_INVERSION = '[ProductoInversionApp] Set ProductoInversionApp';
export const QUIT_PRODUCTOAPP_INVERSION = '[ProductoInversionApp] Quit ProductoInversionApp';

export const SET_PRODUCTOAPPFILTROS_INVERSION = '[InversionAppFiltro] Set InversionAppFiltro';
export const QUIT_PRODUCTOAPPFILTROS_INVERSION = '[InversionAppFiltro] Quit InversionAppFiltro';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetProductoAppInversionAction implements Action{
    readonly type = SET_PRODUCTOAPP_INVERSION;
    constructor (public  productoInversionApp : ProductoInversionApp ){}
}
export class QuitProcuctoAppInversionAction implements Action{
    readonly type = QUIT_PRODUCTOAPP_INVERSION;
    constructor (){}
}

// PRODUCTO ---------------------------------------------------------------------------------------------------------
export class SetProductoInversionAction implements Action{
    readonly type = SET_PRODUCTO_INVERSION;
    constructor (public productoInversion : ProductoInversionPaginador){}
}
export class QuitProductoInversionAction implements Action{
    readonly type = QUIT_PRODUCTO_INVERSION;
    constructor (){}
}

// PRODUCTO MODEL---------------------------------------------------------------------------------------------------------
export class SetProductoInversionModelAction implements Action{
    readonly type = SET_PRODUCTO_INVERSION_MODEL;
    constructor (public productoInversion : ProductInvestment){}
}
export class QuitProductoInversionModelAction implements Action{
    readonly type = QUIT_PRODUCTO_INVERSION_MODEL;
    constructor (){}
}

// CONTRATO ---------------------------------------------------------------------------------------------------------
export class SetContratoInversionAction implements Action{
    readonly type = SET_CONTRATO_INVERSION;
    constructor (public contratoInversion : ContratoInversionPaginador){}
}
export class QuitContratoInversionAction implements Action{
    readonly type = QUIT_CONTRATO_INVERSION;
    constructor (){}
}

// CONTRATO MODEL ---------------------------------------------------------------------------------------------------------
export class SetContratoInversionModelAction implements Action{
    readonly type = SET_CONTRATO_INVERSION_MODEL;
    constructor (public contratoInversion : ContratoInversion){}
}
export class QuitContratoInversionModelAction implements Action{
    readonly type = QUIT_CONTRATO_INVERSION_MODEL;
    constructor (){}
}

// LEAD ---------------------------------------------------------------------------------------------------------
export class SetLeadInversionAction implements Action{
    readonly type = SET_LEAD_INVERSION;
    constructor (public leadInversion : LeadPaginador){}
}
export class QuitLeadInversionAction implements Action{
    readonly type = QUIT_LEAD_INVERSION;
    constructor (){}
}

// LEAD MODEL---------------------------------------------------------------------------------------------------------
export class SetLeadInversionModelAction implements Action{
    readonly type = SET_LEAD_INVERSION_MODEL;
    constructor (public leadInversion : Lead){}
}
export class QuitLeadInversionModelAction implements Action{
    readonly type = QUIT_LEAD_INVERSION_MODEL;
    constructor (){}
}

// COMMISSION ---------------------------------------------------------------------------------------------------------
    // TRIMESTRE 1
    export class SetCommissionInversionT1Action implements Action{
        readonly type = SET_COMMISSION_INVERSION_T1;
        constructor (public commissionInversion: CommissionInversionPaginador){}
    }
    export class QuitCommissionInversionT1Action implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T1;
        constructor (){}
    }

    // COMMISSION MODEL---------------------------------------------------------------------------------------------------------
    export class SetCommissionInversionT1ModelAction implements Action{
        readonly type = SET_COMMISSION_INVERSION_T1_MODEL;
        constructor (public commissionInversion: CommissionInversion){}
    }
    export class QuitCommissionInversionT1ModelAction implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T1_MODEL;
        constructor (){}
    }

    // TRIMESTRE 2
    export class SetCommissionInversionT2Action implements Action{
        readonly type = SET_COMMISSION_INVERSION_T2;
        constructor (public commissionInversion: CommissionInversionPaginador){}
    }
    export class QuitCommissionInversionT2Action implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T2;
        constructor (){}
    }

    // COMMISSION MODEL---------------------------------------------------------------------------------------------------------
    export class SetCommissionInversionT2ModelAction implements Action{
        readonly type = SET_COMMISSION_INVERSION_T2_MODEL;
        constructor (public commissionInversion: CommissionInversion){}
    }
    export class QuitCommissionInversionT2ModelAction implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T2_MODEL;
        constructor (){}
    }

    // TRIMESTRE 3
    export class SetCommissionInversionT3Action implements Action{
        readonly type = SET_COMMISSION_INVERSION_T3;
        constructor (public commissionInversion: CommissionInversionPaginador){}
    }
    export class QuitCommissionInversionT3Action implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T3;
        constructor (){}
    }

    // COMMISSION MODEL---------------------------------------------------------------------------------------------------------
    export class SetCommissionInversionT3ModelAction implements Action{
        readonly type = SET_COMMISSION_INVERSION_T3_MODEL;
        constructor (public commissionInversion: CommissionInversion){}
    }
    export class QuitCommissionInversionT3ModelAction implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T3_MODEL;
        constructor (){}
    }
    
    // TRIMESTRE 4
    export class SetCommissionInversionT4Action implements Action{
        readonly type = SET_COMMISSION_INVERSION_T4;
        constructor (public commissionInversion: CommissionInversionPaginador){}
    }
    export class QuitCommissionInversionT4Action implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T4;
        constructor (){}
    }

    // COMMISSION MODEL---------------------------------------------------------------------------------------------------------
    export class SetCommissionInversionT4ModelAction implements Action{
        readonly type = SET_COMMISSION_INVERSION_T4_MODEL;
        constructor (public commissionInversion: CommissionInversion){}
    }
    export class QuitCommissionInversionT4ModelAction implements Action{
        readonly type = QUIT_COMMISSION_INVERSION_T4_MODEL;
        constructor (){}
    }

// INTERESVARIABLE ---------------------------------------------------------------------------------------------------------
export class SetInteresVariableInversionAction implements Action{
    readonly type = SET_INTERESVARIABLE_INVERSION;
    constructor (public interesVariableInversion: InteresVariableInversion){}
}
export class QuitInteresVariableInversionAction implements Action{
    readonly type = QUIT_INTERESVARIABLE_INVERSION;
    constructor (){}
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroProductoInversionAction implements Action{
    readonly type = SET_PRODUCTOAPPFILTROS_INVERSION;
    constructor (public filtro : Filtro){}
}
export class QuitFiltroProductoInversionAction implements Action{
    readonly type = QUIT_PRODUCTOAPPFILTROS_INVERSION;
    constructor (){}
}

export type acciones= SetProductoAppInversionAction  |  QuitProcuctoAppInversionAction | SetProductoInversionAction | QuitProductoInversionAction 
| SetFiltroProductoInversionAction | QuitFiltroProductoInversionAction | SetContratoInversionAction  |  QuitContratoInversionAction 
| SetLeadInversionAction | QuitLeadInversionAction | SetInteresVariableInversionAction | QuitInteresVariableInversionAction 
| SetCommissionInversionT1Action | QuitCommissionInversionT1Action | SetCommissionInversionT2Action | QuitCommissionInversionT2Action 
| SetCommissionInversionT3Action | QuitCommissionInversionT3Action | SetCommissionInversionT4Action | QuitCommissionInversionT4Action 
| SetProductoInversionModelAction | QuitProductoInversionModelAction | SetContratoInversionModelAction | QuitContratoInversionModelAction 
| SetLeadInversionModelAction | QuitLeadInversionModelAction | SetCommissionInversionT1ModelAction | QuitCommissionInversionT1ModelAction 
| SetCommissionInversionT2ModelAction | QuitCommissionInversionT2ModelAction | SetCommissionInversionT3ModelAction | QuitCommissionInversionT3ModelAction 
| SetCommissionInversionT4ModelAction | QuitCommissionInversionT4ModelAction;
