import { PlanTarjeta } from './../main/tarjetas/models/planTarjeta.model';
import { PlanUsuarioTarjetaPaginador } from './../main/tarjetas/models/planUsuarioTarjetaPaginador.model';
import { PlanTarjetasPaginador } from "app/main/tarjetas/models/planTarjetaPaginador.model";
import { TarjetasPaginador } from "app/main/tarjetas/models/tarjetasPaginador.model";
import { Filtro } from "./filtro.model";

export interface TarjetaState{
  tarjeta ?: TarjetasPaginador,
  planTarjeta ?: PlanTarjetasPaginador,
  planUsuarioTarjeta ?: PlanUsuarioTarjetaPaginador,
  tarjetaCheckout ?: PlanTarjeta,
  filtro ?: Filtro,
}

export class TarjetasApp implements TarjetaState{
    constructor(
      public tarjeta ?: TarjetasPaginador,
      public planTarjeta ?: PlanTarjetasPaginador,
      public planUsuarioTarjeta ?: PlanUsuarioTarjetaPaginador,
      public tarjetaCheckout ?: PlanTarjeta,
      public filtro ?:Filtro,
    ){}
}
