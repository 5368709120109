<form class="mat-card mat-elevation-z4 p-24 mr-24"  (ngSubmit)="cambiarPass()"
      fxLayout="column" fxLayoutAlign="start"
      name="nuevoPass" [formGroup]="nuevoPass" >

<div class="mb-24"   fxLayout="column" fxLayoutAlign="start" >

        <span class="h2 mb-24">Escribe tu Nuevo Password</span>

            <mat-form-field appearance="outline">
                <mat-label>Nuevo Password</mat-label>
                <input matInput type="password" formControlName="newPass" required placeholder="Escribe aquí...">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-hint>Al menos 1 mayúscula, letras, números y 1 símbolo.</mat-hint>
                <mat-error>El password es requerido</mat-error>
            </mat-form-field>




</div>

<div mat-dialog-actions fxLayoutAlign="end">
  <button appearance="outline" class="pr-4" type="submit" mat-raised-button color="accent"
          [disabled]="nuevoPass.invalid">Guardar
    <mat-icon aria-hidden="false" aria-label="Cambiar password">save</mat-icon>
  </button>
</div>
</form>

