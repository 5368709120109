import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {URL_SERVICIOS} from "../../../config/config";


@Injectable({
  providedIn: 'root'
})
export class CotizadorService implements Resolve<any>{
  categorias = [];

  constructor(public http: HttpClient) {

  }


  obtenerProductos(id)
  {
      return this.http.get(URL_SERVICIOS + '/public-products/' + id, {});
  }


  obtenerCategorias(): Promise<any>
  {

    return new Promise((resolve, reject) => {

      this.http.get(URL_SERVICIOS + '/categorias')
        .subscribe((response: any) => {

          this.categorias = response.categorias;


          resolve(response.categorias);
        }, reject);
    });
  }




  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.obtenerCategorias(),
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
}
