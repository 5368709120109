import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import {RolePipe} from "./role.pipe";

@NgModule({
  declarations: [
    ImagenPipe,
    RolePipe
  ],
  exports: [
    ImagenPipe,
    RolePipe
  ]
})
export class PipesModule { }
