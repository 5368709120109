import {Action} from '@ngrx/store';
import { Aplicacion } from 'app/models/aplicacion.model';
import { Partner } from 'app/main/administracion/partner/models/partner.model';
import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { PaginadorTabla } from 'app/models/paginador.model';
import { Filtro } from 'app/models/filtro.model';


export const SET_APLICACION_USUARIO = '[AplicacionUsuario] Set AplicacionUsuario';
export const QUIT_APLICACION_USUARIO = '[AplicacionUsuario] Quit AplicacionUsuario';

export const SET_APLICACION_PARTNER = '[AplicacionPartner] Set AplicacionPartner';
export const QUIT_APLICACION_PARTNER = '[AplicacionPartner] Quit AplicacionPartner';

export const SET_APLICACION = '[Aplicacion] Set Aplicacion';
export const QUIT_APLICACION = '[Aplicacion] Quit Aplicacion';

export const SET_APLICACION_PAGINADOR = '[AplicacionPaginador] Set AplicacionPaginador';
export const QUIT_APLICACION_PAGINADOR = '[AplicacionPaginador] Quit AplicacionPaginador';


export const SET_APLICACION_FILTRO = '[AplicacionFiltro] Set AplicacionFiltro';
export const QUIT_APLICACION_FILTRO = '[AplicacionFiltro] Quit AplicacionFiltro';


export const SET_APLICACION_CURRENT_MODEL_EDIT = '[AplicacionEdit] Set AplicacionModelCurrenEdit';
export const QUIT_APLICACION_CURRENT_MODEL_EDIT = '[AplicacionEdit] Quit AplicacionModelCurrenEdit';


export class SetAplicacionUserAction implements Action{
    readonly type = SET_APLICACION_USUARIO;

    constructor (public usuario : Usuario){
    }
}

export class QuitAplicacionUserAction implements Action{
    readonly type = QUIT_APLICACION_USUARIO;

    constructor (public usuario : Usuario){
    }
}

export class SetAplicacionPartnerAction implements Action{
    readonly type = SET_APLICACION_PARTNER;

    constructor (public partner : Partner){
    }
}

export class QuitAplicacionPartnerAction implements Action{
    readonly type = QUIT_APLICACION_PARTNER;

    constructor (public partner : Partner){
    }
}

export class SetAplicacionAction implements Action{
    readonly type = SET_APLICACION;

    constructor (public app : Aplicacion){
    }
}

export class QuitAplicacionAction implements Action{
    readonly type = QUIT_APLICACION;

    constructor (public app : Aplicacion){
    }
}

//   APP PAGINADOR ---------------------------------------------------------------------------------------------------------
export class SetAplicacionPaginadorAction implements Action{
    readonly type = SET_APLICACION_PAGINADOR;
    constructor (public paginador : PaginadorTabla){}
}
export class QuitAplicacionPaginadorAction implements Action{
    readonly type = QUIT_APLICACION_PAGINADOR;
    constructor (){}
}

//   APP FILTRO ---------------------------------------------------------------------------------------------------------
export class SetAplicacionFiltroAction implements Action{
  readonly type = SET_APLICACION_FILTRO;
  constructor (public filtro : Filtro){}
}
export class QuitAplicacionFiltroAction implements Action{
  readonly type = QUIT_APLICACION_FILTRO;
  constructor (){}
}

//   APP CURRENT EDIT ---------------------------------------------------------------------------------------------------------
export class SetAplicacionCurrentModelEditAction implements Action{
  readonly type = SET_APLICACION_CURRENT_MODEL_EDIT;
  constructor (public model : any){}
}
export class QuitAplicacionCurrentModelEditAction implements Action{
  readonly type = QUIT_APLICACION_CURRENT_MODEL_EDIT;
  constructor (){}
}

export type acciones = SetAplicacionUserAction  |  QuitAplicacionUserAction
| SetAplicacionPartnerAction | QuitAplicacionPartnerAction |  SetAplicacionAction
|  QuitAplicacionAction | SetAplicacionPaginadorAction | QuitAplicacionPaginadorAction
| SetAplicacionFiltroAction | QuitAplicacionFiltroAction | SetAplicacionCurrentModelEditAction
| QuitAplicacionCurrentModelEditAction;
