import * as fromProductoApp from './productosApp.accions';
import { ProductoAppState, ProductoApp } from 'app/models/productosApp.model';


const estadoInicial: ProductoAppState = new ProductoApp();


export function ProductoReducer (state = estadoInicial,action:fromProductoApp.acciones):ProductoAppState{
    switch(action.type){
        //   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
        case fromProductoApp.SET_PRODUCTOAPP:
            let productoApp = { ...action.productoApp };
            localStorage.setItem('productoApp', JSON.stringify(productoApp));
            return productoApp
        case fromProductoApp.QUIT_PRODUCTOAPP:
            return new ProductoApp();

        //   PRODUCTO ---------------------------------------------------------------------------------------------------------
        case fromProductoApp.SET_PRODUCTO:
        let nuevoEstadoProducto = { ...state };
            nuevoEstadoProducto.producto = { ...action.producto };
            localStorage.setItem('productoApp', JSON.stringify(nuevoEstadoProducto));
            return nuevoEstadoProducto;
        case fromProductoApp.QUIT_PRODUCTO:
            localStorage.removeItem('productoApp');
            let quitProducto = { ...state };
            quitProducto.producto.paginador = null;
            return quitProducto;

        //   PRODUCTO MODEL---------------------------------------------------------------------------------------------------------
        case fromProductoApp.SET_PRODUCTO_MODEL:
        let nuevoEstadoProductoModel = { ...state };
        nuevoEstadoProductoModel.producto.producto = { ...action.producto };
            localStorage.setItem('productoAppModel', JSON.stringify(nuevoEstadoProductoModel));
            return nuevoEstadoProductoModel;
        case fromProductoApp.QUIT_PRODUCTO_MODEL:
            localStorage.removeItem('productoAppModel');
            let quitProductoModel = { ...state };
            quitProductoModel.producto.producto = null;
            return quitProductoModel;

        //   FILTRO ---------------------------------------------------------------------------------------------------------
        case fromProductoApp.SET_PRODUCTOAPPFILTROS:
            let filtroProductos = { ...state };
            filtroProductos.filtro = { ...action.filtro };
            localStorage.setItem('productoAppFiltro', JSON.stringify(filtroProductos));
            return filtroProductos;
        case fromProductoApp.QUIT_PRODUCTOAPPFILTROS:
            let quitFiltro = { ...state };
            quitFiltro.filtro = null;
            return quitFiltro;

        default:
            return state;
    }
}
