<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <!-- <div class="logo">
        <img class="logo-icon" src="assets/images/logos/fuse.svg">
        <span class="logo-text secondary-text">GRUPO DIME</span>
    </div> -->

    <div *ngIf="usuarioProfile?.role === 'USER_ROOT'">
      <div class="logo">
        <img class="logo-icon" src="http://www.grupodime.com.mx/img/dime3d2b.png">
        <span class="logo-text">Grupo Dime</span>
      </div>
    </div>

    <div *ngIf="usuarioProfile?.role !== 'USER_ROOT'">
      <div class="logo">
        <img class="logo-icon"  [src]="partner?.img | imagen:'partners'" *ngIf="partner?.img !== null && partner?.img !== undefined && partner?.img !== ''">
        <img class="logo-icon" src="assets/images/logos/fuse.svg" *ngIf="partner?.img === null || partner?.img === undefined || partner?.img === ''">
        <span class="logo-text">{{partner?.nombre}}</span>
      </div>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground" *ngIf="usuario">

        <div class="h3 username">{{usuario.nombre}} {{usuario.surname}}</div>
        <div class="h5 email hint-text mt-8">{{usuario.email}}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar " [src]="usuario.img | imagen">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
