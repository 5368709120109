import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { Partner } from 'app/main/administracion/partner/models/partner.model';
import { PaginadorTabla } from './paginador.model';
import { Filtro } from './filtro.model';

export class Aplicacion{
    constructor(

      public usuarioProfile ?: Usuario,
      public token ?: string,
      public partner ?: Partner,
      public filtro ?: Filtro,
      public paginador ?: PaginadorTabla,

      public current?: any,
      public lastEdit?: String,
    ){}
  }
