export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplicaciones',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            }
        }
    }
};
