import { PaginadorTabla } from './paginador.model';


export class Filtro
{
    constructor(
        public busqueda ?: string,
        public fechaInicio ?: string,
        public fechaFin ?: string,
        public estados  ?: string [],
        public categorias ?: string [],
        public empresas ?: string [],
        public tipo?: string
        //public paginador ?: PaginadorTabla, // este se va a borrar
        ){}

}


export class FiltroUsuarios implements Filtro
{
    constructor(
        public busqueda ?: string,
        public fechaInicio ?: string,
        public fechaFin ?: string,
        public estados  ?: string [],
        public categorias ?: string [],
        public empresas ?: string [],
        public roles ?: string [],
        //public paginador ?: PaginadorTabla, // este se va a borrar
        )
    {

    }

}
