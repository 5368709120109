import { MenuChildren } from './menu/models/menuChildren.model';
import { SubCategoriaPaginador } from './categoria/model/subCategoriaPaginador.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { AdministracionApp } from 'app/models/administracionApp.model';
import { AppState } from 'app/app.reducer';
import { SetAdministracionAppAction,
  QuitdministracionAppAction,
  SetUsuarioAction,
  QuitUsuarioAction,
  SetPartnerAction,
  QuitPartnerAction,
  SetCategoriaAction,
  QuitCategoriaAction,

  SetEmpresaAction,
  QuitEmpresaAction,
  SetGrupoAction,
  QuitGrupoAction,
  SetSubCategoriaAction,
  QuitSubCategoriaAction,
  SetCategoriaModelAction,
  QuitCategoriaModelAction,
  SetSubCategoriaModelAction,
  QuitSubCategoriaModelAction,
  SetEmpresaModelAction,
  QuitEmpresaModelAction,
  SetGrupoModelAction,
  QuitGrupoModelAction,
  SetPartnerModelAction,
  QuitPartnerModelAction,
  SetUsuarioModelAction,
  QuitUsuarioModelAction,
  SetRoleAction,
  QuitRoleAction,
  SetRoleModelAction,
  QuitRoleModelAction,
  SetMenuAction,
  QuitMenuAction,
  SetMenuModelAction,
  QuitMenuModelAction,
  SetMenuChildrenAction,
  QuitMenuChildrenAction
} from 'app/services/reducers/administracionApp/administracionApp.accions';
import { Filtro } from 'app/models/filtro.model';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { UsuarioPaginador } from './usuarios/models/usuarioPaginador.model';
import { PartnerPaginador } from './partner/models/partnerPaginador.model';
import { CategoriaPaginador } from './categoria/model/categoriaPaginador.model';
import { EmpresaPaginador } from './empresa/models/empresaPaginador.model';
import { GrupoPaginador } from './grupo/models/grupoPaginador.model';
import { Usuario } from './usuarios/models/usuario.model';
import { Partner } from './partner/models/partner.model';
import { Categoria } from './categoria/model/categoria.model';
import { SubCategoria } from './categoria/model/subCategoria.model';
import { Empresa } from './empresa/models/empresa.model';
import { Grupo } from './grupo/models/grupo.model';
import { Role } from './role/models/role.model';
import { RolePaginador } from './role/models/rolePaginador.model';
import { MenuPaginador } from './menu/models/menuPaginador.model';
import { Menu } from './menu/models/menu.model';
import { PaginadorTabla } from 'app/models/paginador.model';

@Injectable({
  providedIn: 'root'
})
export class AdministracionAppService implements Resolve<any> {

  administracionApp : AdministracionApp = new AdministracionApp();
  administracionSubscriber : Subscription;

  constructor( private store: Store<AppState>) {}

  crearSubcriber (){
    return this.store.select('administracionApp');
  }

  cambiarUsuarioModel ( usuario : Usuario ){
    this.store.dispatch(new SetUsuarioModelAction(usuario));
  }

  quitarUsuarioModel (){
    this.store.dispatch(new QuitUsuarioModelAction());
  }

  cambiarPartner ( partnerPaginador : PartnerPaginador ){
    this.store.dispatch(new SetPartnerAction(partnerPaginador));
  }

  quitarPartner (){
    this.store.dispatch(new QuitPartnerAction());
  }

  cambiarPartnerModel ( partner : Partner ){
    this.store.dispatch(new SetPartnerModelAction(partner));
  }

  quitarPartnerModel (){
    this.store.dispatch(new QuitPartnerModelAction());
  }

  cambiarCategoria ( categoriaPaginador : CategoriaPaginador ){
    this.store.dispatch(new SetCategoriaAction(categoriaPaginador));
  }

  quitarCategoria (){
    this.store.dispatch(new QuitCategoriaAction());
  }

  cambiarCategoriaModel ( categoria : Categoria ){
    this.store.dispatch(new SetCategoriaModelAction(categoria));
  }

  quitarCategoriaModel (){
    this.store.dispatch(new QuitCategoriaModelAction());
  }

  cambiarSubCategoria ( subCategoriaPaginador : SubCategoriaPaginador ){
    this.store.dispatch(new SetSubCategoriaAction(subCategoriaPaginador));
  }

  quitarSubCategoria (){
    this.store.dispatch(new QuitSubCategoriaAction());
  }

  cambiarSubCategoriaModel ( subCategoria: SubCategoria ){
    this.store.dispatch(new SetSubCategoriaModelAction(subCategoria));
  }

  quitarSubCategoriaModel (){
    this.store.dispatch(new QuitSubCategoriaModelAction());
  }

  cambiarEmpresa ( empresaPaginador : EmpresaPaginador ){
    this.store.dispatch(new SetEmpresaAction(empresaPaginador));
  }

  quitarEmpresa (){
    this.store.dispatch(new QuitEmpresaAction());
  }

  cambiarEmpresaModel ( empresa : Empresa ){
    this.store.dispatch(new SetEmpresaModelAction(empresa));
  }

  quitarEmpresaModel (){
    this.store.dispatch(new QuitEmpresaModelAction());
  }

  cambiarGrupo ( grupoPaginador : GrupoPaginador ){
    this.store.dispatch(new SetGrupoAction(grupoPaginador));
  }

  quitarGrupo (){
    this.store.dispatch(new QuitGrupoAction());
  }

  cambiarGrupoModel ( grupo : Grupo ){
    this.store.dispatch(new SetGrupoModelAction(grupo));
  }

  quitarGrupoModel (){
    this.store.dispatch(new QuitGrupoModelAction());
  }

  cambiarRole ( rolePaginador : RolePaginador ){
    this.store.dispatch(new SetRoleAction(rolePaginador));
  }

  quitarRole (){
    this.store.dispatch(new QuitRoleAction());
  }

  cambiarRoleModel ( role : Role ){
    this.store.dispatch(new SetRoleModelAction(role));
  }

  quitarRoleModel (){
    this.store.dispatch(new QuitRoleModelAction());
  }

  cambiarMenu ( menuPaginador : MenuPaginador ){
    this.store.dispatch(new SetMenuAction(menuPaginador));
  }

  quitarMenu (){
    this.store.dispatch(new QuitMenuAction());
  }

  cambiarMenuModel ( menu : Menu ){
    this.store.dispatch(new SetMenuModelAction(menu));
  }

  quitarMenuModel (){
    this.store.dispatch(new QuitMenuModelAction());
  }

  cambiarMenuChildren ( menuChildren : MenuChildren ){
    this.store.dispatch(new SetMenuChildrenAction(menuChildren));
  }

  quitarMenuChildren (){
    this.store.dispatch(new QuitMenuChildrenAction());
  }

  setAdministracion ( administracionApp : AdministracionApp ){
    this.store.dispatch(new SetAdministracionAppAction(administracionApp));
  }

  quitarAdministracion (){
    this.store.dispatch(new QuitdministracionAppAction());
  }


  eliminarStore(){
    this.administracionSubscriber.unsubscribe();
  }

  cargarStorage(): Promise<any> {
    return new Promise ((resolve, reject ) =>{
      let administracion: AdministracionApp = new AdministracionApp();
      if (localStorage.getItem('administracionApp')) {
        administracion = JSON.parse(localStorage.getItem('administracionApp'));
      }
      if (!administracion.filtro){
        administracion.filtro = new Filtro ();
      }
      this.setAdministracion(administracion);
      resolve(administracion);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.cargarStorage(), //  carga el ultimo estado del modulo de contratos
      ]).then(
        ([administracionApp]) => {
          this.administracionApp = administracionApp;
          resolve();
        },
        reject
      );
    });
  }



}
