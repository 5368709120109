import {Injectable} from '@angular/core';
import { URL_SERVICIOS } from 'app/config/config';
import { HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagenService {

  constructor() {
  }


  subirArchivo(archivo: File, tipo: string, id: string) {

    let token = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();

        formData.append('imagen', archivo, archivo.name?.toLocaleLowerCase());

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            //console.log('Imagen Subida');
            resolve(JSON.parse(xhr.response));
          } else {
            //console.log('fallo la subida');
            resolve(JSON.parse(xhr.response));
          }
        }
      };
      let url = URL_SERVICIOS + '/upload/' + tipo + '/' + id;
      xhr.open('PUT', url, true);
      xhr.setRequestHeader(environment.tokenName, token);

      xhr.send(formData);
    });
  }
}
