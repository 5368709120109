import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.reducer';
import {URL_SERVICIOS} from 'app/config/config';
import {ImagenService} from '../../../../services/imagen/imagen.service';
import {ToastrService} from 'ngx-toastr';
import {Observable, Subscription} from "rxjs";
import { AppService } from 'app/app.service';
import { Usuario } from '../models/usuario.model';
import * as moment from 'moment';
import {Partner} from '../../partner/models/partner.model';
import {Empresa} from '../../empresa/models/empresa.model';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AngularFireAuth } from '@angular/fire/auth';
import 'firebase/auth';
import firebase from 'firebase';
import database = firebase.database;

@Injectable({
  providedIn: 'root'
})
export class UsuarioProfileService implements Resolve<any> {
  idUsuario: string;
  appSubscriber: Subscription;
  usuario: Usuario = new Usuario();
  routeParams: any;
  err: any;
  partner: Partner;
  usuarioProfile: Usuario;
  token: string;
  menu: any = [];
  empresa: Empresa;

  constructor(
    public http: HttpClient,
    public router: Router,
    public store: Store<AppState>,
    public imagenService: ImagenService,
    public toasterService: ToastrService,
    public appService: AppService,
    public fuseNavigationService: FuseNavigationService,
    public afAuth: AngularFireAuth,
  ) {}

  estaLogueado() {
    return this.http.get(URL_SERVICIOS + '/login/verify-user' );

  }




  logOut() {
    this.usuario = null;
    this.token = '';
    this.appService.quitarUsuario(this.usuario);
    this.router.navigate(['/login']);
    this.afAuth.signOut();
  }

  /**
   * Ejecuta el login a traves de Firebase, si este no existe y se encuentra registrado como usuario y el password
   * corresponde se registra en firebase asociando el uid que se obtuvo.
   * @param usuario
   * @param recuerdame
   */
  async login(usuario: Usuario, recuerdame: boolean = false) {
    if (recuerdame) {
      localStorage.setItem('email', usuario.email);
    } else {
      localStorage.removeItem('email');
    }

    return new Promise(async (resolve, reject) => {
      var isLog = false;
      var user = await this.afAuth.signInWithEmailAndPassword(usuario.email, usuario.password).then(async (resp) => {
        isLog = true;
        let url = URL_SERVICIOS + '/login';
        usuario['loginByFirebase'] = true;
        var login = await this.http.post(url, usuario).subscribe((response: any) => {
          this.usuario = response.usuario;
          this.appService.cambiarUsuario(response.usuario);
          localStorage.setItem('token', response.token);
          this.token = response.token;
          localStorage.setItem('menu',JSON.stringify(response.menu) );


          this.menu = response.menu;
          //this.fuseNavigationService.setCurrentNavigation(this.menu);
          //this.fuseNavigationService.unregister('main');
          this.fuseNavigationService.addNavigationItem('main', this.menu);

          //var user: Usuario = this.usuario;
          resolve(this.usuario);
        }, (err) => {
          this.err = err;
          reject(this.err);
        });
        return user;
      }, error=>{
        console.log(error)
        this.http.post(URL_SERVICIOS + '/login', usuario).subscribe((response:any) => {

          this.usuario = response.usuario;
          this.appService.cambiarUsuario(response.usuario);
          localStorage.setItem('token', response.token);
          this.token = response.token;
          localStorage.setItem('menu',JSON.stringify(response.menu) );


          this.menu = response.menu;
          this.fuseNavigationService.addNavigationItem('main', this.menu);

          this.afAuth.createUserWithEmailAndPassword(usuario.email, usuario.password).then(
            data => {
              let newUser: Usuario = new Usuario();
              newUser._id = this.usuario._id;
              newUser.uidFirebase = data.user.uid;
              console.log('newUser', newUser); // here you get the result
              this.actualizarUsuario(newUser).then(updated=>{
                resolve(this.usuario);
              });
            }

          , (error: any) => {
              this.err = error;
              reject(this.err);
            }) ;


          //  var useract = await this.actualizarUsuario(newUser);

        }, (error: any) => {
          this.err = error;
          reject(this.err);
        });

      })
    });

  }

  async resetPassword(email: string): Promise<void>{
    return new Promise(async (resolve, reject) => {
      try{
        var sendE = await this.afAuth.sendPasswordResetEmail(email);
        resolve(sendE);
      }
      catch (error){
        reject(error);
      }
    });
  }

  async crearUsuarioFirebase(usuario: Usuario){
    const {user} = await this.afAuth.createUserWithEmailAndPassword(usuario.email, usuario.password);
    let newUser: Usuario = new Usuario();
    newUser._id = usuario._id;
    newUser.uidFirebase = user.uid;
    var useract = await this.actualizarUsuario(newUser);
    return true;
  }

  actualizarUsuario(usuario: Usuario){
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });

      this.http.put(URL_SERVICIOS + '/usuario/' + usuario._id, usuario, {headers})
        .subscribe((response: any) => {
          let user:Usuario = response.usuario;
          user.password = ':)'
          this.appService.cambiarUsuario(user);
          resolve(this.usuario);
        }, reject);
    });
  }

  /**
   * Get Complejos
   *
   * @returns {Promise<any>}
   */
  getUserProfile(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });

      this.http.get(URL_SERVICIOS + '/usuario/'+this.routeParams.id, {headers})
        .subscribe((response: any) => {
          ////console.log(response.usuario);
          this.usuario = response.usuario;
          //console.log(this.usuario);
          //this.complejos = response.complejo;
          resolve(this.usuario);
        }, reject);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        //this.obtenerUsuarios({role: 'USER_SALE', busqueda: '', pageNumber:1, pageSize: 10}),
        //this.obtenerUsuarios({role: 'ROLE_USER', busqueda: '', pageNumber:1, pageSize: 10}),
        this.getUserProfile()

      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }


}
