import { ContratoPaginador } from 'app/main/contrato/model/contratoPaginador.model';
import { Filtro } from './filtro.model';

export interface AppContratoState{

  contrato ?: ContratoPaginador,
  filtro ?: Filtro,
}

export class ContratoApp implements AppContratoState{
    constructor(
      public contrato ?:ContratoPaginador,
      public filtro ?
    ){}
  }
