import * as fromContratoApp from './contratosApp.accions';
import { ContratosApp, ContratoAppState } from 'app/models/contratosApp.model';


const estadoInicial: ContratoAppState = new ContratosApp();


export function ContratosReducer(state = estadoInicial, action: fromContratoApp.acciones): ContratoAppState {
    switch (action.type) {
        //   FILTRO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_FILTROCONTRATOS:
            let filtroContratos = { ...state };
            filtroContratos.filtro = { ...action.filtro };
            localStorage.setItem('filtroContratos', JSON.stringify(filtroContratos));
            return filtroContratos;
        case fromContratoApp.QUIT_FILTROCONTRATOS:
            let quitFiltro = { ...state };
            quitFiltro.filtro = null;
            return quitFiltro;

        //   CONTRATO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATO:
            let nuevoEstado = { ...state };
            nuevoEstado.contrato = { ...action.contrato };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstado));
            return nuevoEstado;
        case fromContratoApp.QUIT_CONTRATO:
            let quitContrato = { ...state };
            quitContrato.contrato.paginador = null;
            return quitContrato;

        //   CONTRATO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATO_MODEL:
            let nuevoEstadoModel = { ...state };
            nuevoEstadoModel.contrato.contrato = { ...action.contrato };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoModel));
            return nuevoEstadoModel;
        case fromContratoApp.QUIT_CONTRATO_MODEL:
            let quitContratoModel = { ...state };
            quitContratoModel.contrato.contrato = null;
            return quitContratoModel;

        //   INGRESO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_INGRESO:
            let nuevoEstadoIngreso = { ...state };
            nuevoEstadoIngreso.ingreso = { ...action.ingreso };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoIngreso));
            return nuevoEstadoIngreso;
        case fromContratoApp.QUIT_INGRESO:
            let quitIngreso = { ...state };
            quitIngreso.ingreso.paginador = null;
            return quitIngreso;

        //   INGRESO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_INGRESO_MODEL:
            let nuevoEstadoIngresoModel = { ...state };
            nuevoEstadoIngresoModel.ingreso.ingreso = { ...action.ingreso };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoIngresoModel));
            return nuevoEstadoIngresoModel;
        case fromContratoApp.QUIT_INGRESO_MODEL:
            let quitIngresoModel = { ...state };
            quitIngresoModel.ingreso.ingreso = null;
            return quitIngresoModel;

        //   EGRESO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_EGRESO:
            let nuevoEstadoEgreso = { ...state };
            nuevoEstadoEgreso.egreso = { ...action.egreso };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoEgreso));
            return nuevoEstadoEgreso;
        case fromContratoApp.QUIT_EGRESO:
            let quitEgreso = { ...state };
            quitEgreso.egreso.paginador = null;
            return quitEgreso;

        //   EGRESO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_EGRESO_MODEL:
            let nuevoEstadoEgresoModel = { ...state };
            nuevoEstadoEgresoModel.egreso.egreso = { ...action.egreso };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoEgresoModel));
            return nuevoEstadoEgresoModel;
        case fromContratoApp.QUIT_EGRESO_MODEL:
            let quitEgresoModel = { ...state };
            quitEgresoModel.egreso.egreso = null;
            return quitEgresoModel;

        //   COMPLEJO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_COMPLEJO:
            let nuevoEstadoComplejo = { ...state };
            nuevoEstadoComplejo.complejo = { ...action.complejo };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoComplejo));
            return nuevoEstadoComplejo;
        case fromContratoApp.QUIT_COMPLEJO:
            let quitComplejo = { ...state };
            quitComplejo.complejo = null;
            return quitComplejo;

        //   COMPLEJO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_COMPLEJO_MODEL:
            let nuevoEstadoComplejoModel = { ...state };
            nuevoEstadoComplejoModel.complejo = { ...action.complejo };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoComplejoModel));
            return nuevoEstadoComplejoModel;
        case fromContratoApp.QUIT_COMPLEJO_MODEL:
            let quitComplejoModel = { ...state };
            quitComplejoModel.complejo = null;
            return quitComplejoModel;

        //   DEPARTAMENTO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_DEPARTAMENTO:
            let nuevoEstadoDepartamento = { ...state };
            nuevoEstadoDepartamento.departamento = { ...action.departamento };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoDepartamento));
            return nuevoEstadoDepartamento;
        case fromContratoApp.QUIT_DEPARTAMENTO:
            let quitDepartamento = { ...state };
            quitDepartamento.departamento.paginador = null;
            return quitDepartamento;

        //   DEPARTAMENTO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_DEPARTAMENTO_MODEL:
            let nuevoEstadoDepartamentoModel = { ...state };
            nuevoEstadoDepartamentoModel.departamento.departamento = { ...action.departamento };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoDepartamentoModel));
            return nuevoEstadoDepartamentoModel;
        case fromContratoApp.QUIT_DEPARTAMENTO_MODEL:
            let quitDepartamentoModel = { ...state };
            quitDepartamentoModel.departamento.departamento = null;
            return quitDepartamentoModel;

        //   CASA ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CASA:
          let nuevoEstadoCasa = { ...state };
          nuevoEstadoCasa.casa = { ...action.casa };
          localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoCasa));
          return nuevoEstadoCasa;
        case fromContratoApp.QUIT_CASA:
            let quitCasa = { ...state };
            quitCasa.casa.paginador = null;
            return quitCasa;

        //   CASA MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CASA_MODEL:
          let nuevoEstadoCasaModel = { ...state };
          nuevoEstadoCasaModel.casa.casa = { ...action.casa };
          localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoCasaModel));
          return nuevoEstadoCasaModel;
        case fromContratoApp.QUIT_CASA_MODEL:
            let quitCasaModel = { ...state };
            quitCasaModel.casa.casa = null;
            return quitCasaModel;

        //   TERRENO ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_PREDIO:
          let nuevoEstadoPredio = { ...state };
          nuevoEstadoPredio.predio = { ...action.predio };
          localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoPredio));
          return nuevoEstadoPredio;
        case fromContratoApp.QUIT_PREDIO:
            let quitPredio = { ...state };
            quitPredio.predio.paginador = null;
            return quitPredio;

        //   TERRENO MODEL---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_PREDIO_MODEL:
          let nuevoEstadoPredioModel = { ...state };
          nuevoEstadoPredioModel.predio.predio = { ...action.predio };
          localStorage.setItem('contratosApp', JSON.stringify(nuevoEstadoPredioModel));
          return nuevoEstadoPredioModel;
        case fromContratoApp.QUIT_PREDIO_MODEL:
            let quitPredioModel = { ...state };
            quitPredioModel.predio.predio = null;
            return quitPredioModel;

        //   CONTRATOSAPP ---------------------------------------------------------------------------------------------------------
        case fromContratoApp.SET_CONTRATOAPP:
            let nuevoApp = { ...action.contratosApp };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoApp));
            return nuevoApp
        case fromContratoApp.QUIT_CONTRATOAPP:
            return new ContratosApp();

        default:
            return state;
    }
}
