
import { Filtro } from './filtro.model';
import { ProductoInversionPaginador } from 'app/main/inversiones/product-investment/models/product-investmentPaginador.model';
import { ContratoInversionPaginador } from 'app/main/inversiones/contrato/model/contratoInversionPagina.model';
import { LeadPaginador } from '../main/inversiones/leads/models/leadPaginador.model';
import { InteresVariableInversion } from 'app/main/inversiones/interesVariable/models/interesVariable.model';
import { CommissionInversionPaginador } from 'app/main/inversiones/commission/models/commisionInversionPaginador';



export interface ProductoInversionAppState{
  productoInversion ?: ProductoInversionPaginador,
  contratoInversion ?: ContratoInversionPaginador,
  leadInversion ?: LeadPaginador,
  interesVariableInversion ?: InteresVariableInversion,
  commissionInversionT1 ?: CommissionInversionPaginador,
  commissionInversionT2 ?: CommissionInversionPaginador,
  commissionInversionT3 ?: CommissionInversionPaginador,
  commissionInversionT4 ?: CommissionInversionPaginador,
  filtro ?: Filtro,
}



export class ProductoInversionApp implements ProductoInversionAppState{
    constructor(
      public productoInversion ?: ProductoInversionPaginador,
      public contratoInversion ?: ContratoInversionPaginador,
      public leadInversion ?: LeadPaginador,
      public interesVariableInversion ?: InteresVariableInversion,
      public commissionInversionT1 ?: CommissionInversionPaginador,
      public commissionInversionT2 ?: CommissionInversionPaginador,
      public commissionInversionT3 ?: CommissionInversionPaginador,
      public commissionInversionT4 ?: CommissionInversionPaginador,
      public filtro ?: Filtro,
    ){}
  }
