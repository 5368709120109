import * as fromProductoInversionApp from './inversionApp.accions';
import { ProductoInversionAppState, ProductoInversionApp } from 'app/models/productosInversion.model';


const estadoInicial: ProductoInversionAppState = new ProductoInversionApp();


export function ProductoInversionReducer (state = estadoInicial,action:fromProductoInversionApp.acciones):ProductoInversionAppState{
    switch(action.type){
        //   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_PRODUCTOAPP_INVERSION:
            let productoInversionApp = { ...action.productoInversionApp };
            localStorage.setItem('productoInversionApp', JSON.stringify(productoInversionApp));
            return productoInversionApp
        case fromProductoInversionApp.QUIT_PRODUCTOAPP_INVERSION:
            return new ProductoInversionApp();

       //   PRODUCTO ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_PRODUCTO_INVERSION:
            let nuevoEstadoProducto = { ...state };
            nuevoEstadoProducto.productoInversion = { ...action.productoInversion };
            localStorage.setItem('productoInversionApp', JSON.stringify(nuevoEstadoProducto));
            return nuevoEstadoProducto;
        case fromProductoInversionApp.QUIT_PRODUCTO_INVERSION:
            localStorage.removeItem('productoInversionApp');
            let quitProductoInversion = { ...state };
            quitProductoInversion.productoInversion.paginador = null;
            return quitProductoInversion;

       //   PRODUCTO MODEL---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_PRODUCTO_INVERSION_MODEL:
            let nuevoEstadoProductoModel = { ...state };
            nuevoEstadoProductoModel.productoInversion.productoInversion = { ...action.productoInversion };
            localStorage.setItem('productoInversionAppModel', JSON.stringify(nuevoEstadoProductoModel));
            return nuevoEstadoProductoModel;
        case fromProductoInversionApp.QUIT_PRODUCTO_INVERSION_MODEL:
            localStorage.removeItem('productoInversionAppModel');
            let quitProductoInversionModel = { ...state };
            quitProductoInversionModel.productoInversion.productoInversion = null;
            return quitProductoInversionModel;

        //   CONTRATO ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_CONTRATO_INVERSION:
            let nuevoEstadoContrato = { ...state };
            nuevoEstadoContrato.contratoInversion = { ...action.contratoInversion };
            localStorage.setItem('contratoInversionApp', JSON.stringify(nuevoEstadoContrato));
            return nuevoEstadoContrato;
        case fromProductoInversionApp.QUIT_CONTRATO_INVERSION:
            localStorage.removeItem('contratoInversionApp');
            let quitContratoInversion = { ...state };
            quitContratoInversion.contratoInversion.paginador = null;
            return quitContratoInversion;

        //   CONTRATO MODEL---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_CONTRATO_INVERSION_MODEL:
            let nuevoEstadoContratoModel = { ...state };
            nuevoEstadoContratoModel.contratoInversion.contrato = { ...action.contratoInversion };
            localStorage.setItem('contratoInversionAppModel', JSON.stringify(nuevoEstadoContratoModel));
            return nuevoEstadoContratoModel;
        case fromProductoInversionApp.QUIT_CONTRATO_INVERSION_MODEL:
            localStorage.removeItem('contratoInversionAppModel');
            let quitContratoInversionModel = { ...state };
            quitContratoInversionModel.contratoInversion.contrato = null;
            return quitContratoInversionModel;

        //   LEAD ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_LEAD_INVERSION:
            let nuevoEstadoLead = { ...state };
            nuevoEstadoLead.leadInversion = { ...action.leadInversion };
            localStorage.setItem('leadInversionApp', JSON.stringify(nuevoEstadoLead));
            return nuevoEstadoLead;
        case fromProductoInversionApp.QUIT_LEAD_INVERSION:
            localStorage.removeItem('leadInversionApp');
            let quitLead = { ...state };
            quitLead.leadInversion.paginador = null;
            return quitLead;

        //   LEAD MODEL---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_LEAD_INVERSION_MODEL:
            let nuevoEstadoLeadModel = { ...state };
            nuevoEstadoLeadModel.leadInversion.lead = { ...action.leadInversion };
            localStorage.setItem('leadInversionAppModel', JSON.stringify(nuevoEstadoLeadModel));
            return nuevoEstadoLeadModel;
        case fromProductoInversionApp.QUIT_LEAD_INVERSION_MODEL:
            localStorage.removeItem('leadInversionAppModel');
            let quitLeadModel = { ...state };
            quitLeadModel.leadInversion.lead = null;
            return quitLeadModel;

        //   COMMISSION ---------------------------------------------------------------------------------------------------------
            //   TRIMESTRE 1 ---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T1:
                let nuevoEstadoCommissionT1 = { ...state };
                nuevoEstadoCommissionT1.commissionInversionT1 = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT1App', JSON.stringify(nuevoEstadoCommissionT1));
                return nuevoEstadoCommissionT1;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T1:
                localStorage.removeItem('commissionInversionT1App');
                let quitComisionT1 = { ...state };
                quitComisionT1.commissionInversionT1.paginador = null;
                return quitComisionT1;

            //   COMMISSION MODEL---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T1_MODEL:
                let nuevoEstadoCommissionModelT1 = { ...state };
                nuevoEstadoCommissionModelT1.commissionInversionT1.commission = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT1AppModel', JSON.stringify(nuevoEstadoCommissionModelT1));
                return nuevoEstadoCommissionModelT1;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T1_MODEL:
                localStorage.removeItem('commissionInversionT1AppModel');
                let quitComisionModelT1 = { ...state };
                quitComisionModelT1.commissionInversionT1.commission = null;
                return quitComisionModelT1;
            //   TRIMESTRE 2 ---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T2:
                let nuevoEstadoCommissionT2 = { ...state };
                nuevoEstadoCommissionT2.commissionInversionT2 = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT2App', JSON.stringify(nuevoEstadoCommissionT2));
                return nuevoEstadoCommissionT2;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T2:
                localStorage.removeItem('commissionInversionT2App');
                let quitComisionT2 = { ...state };
                quitComisionT2.commissionInversionT2.paginador = null;
                return quitComisionT2;

            //   COMMISSION MODEL---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T2_MODEL:
                let nuevoEstadoCommissionModelT2 = { ...state };
                nuevoEstadoCommissionModelT2.commissionInversionT2.commission = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT2AppModel', JSON.stringify(nuevoEstadoCommissionModelT2));
                return nuevoEstadoCommissionModelT2;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T2_MODEL:
                localStorage.removeItem('commissionInversionT2AppModel');
                let quitComisionModelT2 = { ...state };
                quitComisionModelT2.commissionInversionT2.commission = null;
                return quitComisionModelT2;
            //   TRIMESTRE 3 ---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T3:
                let nuevoEstadoCommissionT3 = { ...state };
                nuevoEstadoCommissionT3.commissionInversionT3 = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT3App', JSON.stringify(nuevoEstadoCommissionT3));
                return nuevoEstadoCommissionT3;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T3:
                localStorage.removeItem('commissionInversionT3App');
                let quitComisionT3 = { ...state };
                quitComisionT3.commissionInversionT3.paginador = null;
                return quitComisionT3;

            //   COMMISSION MODEL---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T3_MODEL:
                let nuevoEstadoCommissionModelT3 = { ...state };
                nuevoEstadoCommissionModelT3.commissionInversionT3.commission = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT3AppModel', JSON.stringify(nuevoEstadoCommissionModelT3));
                return nuevoEstadoCommissionModelT3;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T3_MODEL:
                localStorage.removeItem('commissionInversionT3AppModel');
                let quitComisionModelT3 = { ...state };
                quitComisionModelT3.commissionInversionT3.commission = null;
                return quitComisionModelT3;
            //   TRIMESTRE 4 ---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T4:
                let nuevoEstadoCommissionT4 = { ...state };
                nuevoEstadoCommissionT4.commissionInversionT4 = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT4App', JSON.stringify(nuevoEstadoCommissionT4));
                return nuevoEstadoCommissionT4;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T4:
                localStorage.removeItem('commissionInversionT4App');
                let quitComisionT4 = { ...state };
                quitComisionT4.commissionInversionT4.paginador = null;
                return quitComisionT4;

            //   COMMISSION MODEL---------------------------------------------------------------------------------------------------------
            case fromProductoInversionApp.SET_COMMISSION_INVERSION_T4_MODEL:
                let nuevoEstadoCommissionModelT4 = { ...state };
                nuevoEstadoCommissionModelT4.commissionInversionT4.commission = { ...action.commissionInversion };
                localStorage.setItem('commissionInversionT4AppModel', JSON.stringify(nuevoEstadoCommissionModelT4));
                return nuevoEstadoCommissionModelT4;
            case fromProductoInversionApp.QUIT_COMMISSION_INVERSION_T4_MODEL:
                localStorage.removeItem('commissionInversionT4AppModel');
                let quitComisionModelT4 = { ...state };
                quitComisionModelT4.commissionInversionT4.commission = null;
                return quitComisionModelT4;

        //   INTERESVARIABLE ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_INTERESVARIABLE_INVERSION:
            let nuevoEstadoInteresVariable = { ...state };
            nuevoEstadoInteresVariable.interesVariableInversion = { ...action.interesVariableInversion };
            localStorage.setItem('interesVariableInversionApp', JSON.stringify(nuevoEstadoInteresVariable));
            return nuevoEstadoInteresVariable;
        case fromProductoInversionApp.QUIT_INTERESVARIABLE_INVERSION:
            localStorage.removeItem('interesVariableInversionApp');
            let quiInteresVariable = { ...state };
            quiInteresVariable.interesVariableInversion = null;
            return quiInteresVariable;

         //   FILTRO ---------------------------------------------------------------------------------------------------------
        case fromProductoInversionApp.SET_PRODUCTOAPPFILTROS_INVERSION:
            let filtroProductos = { ...state };
            filtroProductos.filtro = { ...action.filtro };
            localStorage.setItem('productoInversionApp', JSON.stringify(filtroProductos));
            return filtroProductos;
        case fromProductoInversionApp.QUIT_PRODUCTOAPPFILTROS_INVERSION:
            let quitFiltro = { ...state };
            quitFiltro.filtro = null;
            return quitFiltro;

        default:
            return state;
    }
}
