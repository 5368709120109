
import { ActionReducerMap } from '@ngrx/store';
import * as fromApp from './services/reducers/aplicacion/aplicacion.reducer';
import * as fromContratosApp from './services/reducers/contratosApp/contratosApp.reducer';
import * as fromAdministracionApp from './services/reducers/administracionApp/administracionApp.reducer';
import { ContratosApp } from 'app/models/contratosApp.model';
import { AdministracionApp } from './models/administracionApp.model';
import { ProductoApp } from './models/productosApp.model';
import * as fromProductoApp from './services/reducers/productosApp/productosApp.reducer';
import { ProductoInversionApp } from './models/productosInversion.model';
import * as fromProductoInversionApp from './services/reducers/inversionApp/inversionApp.reducer';
import { ControlEscolarApp } from './models/controlEscolarApp.model';
import * as fromControlEscolarApp from './services/reducers/controlEscolarApp/controlEscolarApp.reducer';
import * as fromTarjetasApp from './services/reducers/tarjetas/tarjeta.reducer';
import { TarjetasApp } from './models/tarjetasApp.model';
import * as fromConfiguracionesApp from './services/reducers/configuraciones/configuracion.reducer';
import { ConfiguracionesApp } from './models/configuracionesApp.model';
import { ContratoApp } from './models/contratoApp.model';
import * as fromContratoApp from './services/reducers/contratoApp/contratoApp.reducer';


export interface AppState{
    app : fromApp.AplicacionState,
    contratosApp : ContratosApp,
    administracionApp : AdministracionApp,
    productoApp : ProductoApp,
    productoInversionApp : ProductoInversionApp,
    controlEscolarApp : ControlEscolarApp,
    tarjetasApp : TarjetasApp,
    contratoApp: ContratoApp,
    configuracionesApp : ConfiguracionesApp,
}
export const appReducers: ActionReducerMap<AppState> = {
    app : fromApp.AplicacionReducer,
    contratosApp : fromContratosApp.ContratosReducer,
    contratoApp : fromContratoApp.ContratoReducer,
    administracionApp : fromAdministracionApp.BusquedaReducer,
    productoApp : fromProductoApp.ProductoReducer,
    productoInversionApp: fromProductoInversionApp.ProductoInversionReducer,
    controlEscolarApp: fromControlEscolarApp.ControlEscolarReducer,
    tarjetasApp: fromTarjetasApp.TarjetaReducer,
    configuracionesApp: fromConfiguracionesApp.ConfiguracionReducer
};
