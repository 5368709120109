import { AppService } from 'app/app.service';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccessibleRoute implements CanActivateChild {

    constructor(
        protected injector: Injector,
        public router: Router,
        public appService: AppService
    ) {}

    async canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Promise<boolean>  {
      var data = {
        ruta: state.url
      };

      var canActivat = await this.appService.compruebaRuta(data).then(
        (resp: any) => {
            return resp.puedeAcceder;
        }, (err) => {

            return false;
        }
      );

      if (canActivat) {
          return true;
      } else {
        this.router.navigate(['/login'])
        return false;
      }

    }
  }
