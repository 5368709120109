<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <!--a href="https://www.inclusive.com.mx"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">favorite</mat-icon>
            <span>Inclusive </span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div-->

    </div>

</mat-toolbar>
