import { Component, ViewEncapsulation, Inject } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmpresaService } from 'app/main/administracion/empresa/services/empresa.service';
import { Empresa } from 'app/main/administracion/empresa/models/empresa.model';
import { AppState } from 'app/app.reducer';
import { Store } from '@ngrx/store';
import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { UsuarioService } from 'app/main/administracion/usuarios/services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ContratoTerreno from 'app/main/contratosv2/contrato/model/contrato.model';
import { Contrato } from 'app/main/contrato/model/contrato.model';
import { ContratoService } from 'app/main/contrato/services/contrato.service';
import * as ContratoTerrenoService from 'app/main/contratosv2/contrato/services/contrato.service';


export interface DialogData {
    usuario: Usuario,
    contrato: Contrato
  }

@Component({
    selector     : 'password',
    templateUrl  : './password.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})


export class PasswordComponent
{



    empresas:Empresa []=[];
    form: FormGroup;
    nuevoPass: FormGroup;
    usuarioBool: Boolean = false;
    contratoBool: Boolean = false;
    contratoBoolTerrenos: Boolean = false;
    usuario: Usuario = new Usuario();
    contratoTerrenos: ContratoTerreno.Contrato;
    contrato: Contrato = new Contrato();
    constructor(
        private _formBuilder: FormBuilder,
        public store: Store<AppState>,
        public usuarioService:UsuarioService,
        public toasterService: ToastrService,
        public contratoTerrenosService : ContratoTerrenoService.ContratoService,
        public contratoService : ContratoService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public datos,
        public dialogRef: MatDialogRef<PasswordComponent>
        ){

    }

    ngOnInit(): void {
        // console.log('Inicializando Password');
        // console.log(this.datos);
        if (this.datos.contratoTerrenos){
          this.contratoBoolTerrenos = true;
          this.contratoTerrenos = this.datos.contrato;
        }
        if (this.datos.contrato){
            this.contratoBool = true;
            this.contrato = this.datos.contrato;
            // console.log(this.contrato);
        }else if (this.datos.usuario){
            this.usuarioBool = true;
            this.usuario = this.datos.usuario;
        }

        this.nuevoPass = this._formBuilder.group({
            newPass: ['', Validators.required],
        });
    }

    cambiarPass(){
        var valores = this.nuevoPass.getRawValue();

        if (this.usuarioBool === true){
            //this.usuario.password=valores.newPass;


            this.usuarioService.actualizarPass({_id: this.usuario._id, password:valores.newPass})
            .subscribe((resp:any)=>{

                this.toasterService.success('','El Password se ha actualizado Correctmente');
                this.dialog.closeAll();
            },
            err => {
                this.toasterService.error('','El Password No se ha actualizado Correctmente');
            });
        }
        if (this.contratoBool) {
          this.contrato.password = valores.newPass;
          this.contratoService.actualizarPassContrato(this.contrato)
            .subscribe((resp:any)=>{
                this.toasterService.success('','El Password se ha actualizado Correctmente');
                this.dialog.closeAll();
            },
            err => {
                this.toasterService.error('','El Password No se ha actualizado Correctmente');
            });
        }
    }
}

