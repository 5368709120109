// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,


  urlServices1: 'https://srv.grupodime.com.mx/api',
  urlServices : 'https://bc.grupodime.com.mx/api',
  urlPublicCard: 'https://vc.grupodime.com.mx/',
  urlServicesCuej: 'http://bc.grupodime.com.mx/api',

  /*
  urlServices: 'http://bc.grupodime.com.mx/api',
  urlPublicCard: 'http://bc.grupodime.com.mx/',
  urlServicesCuej: 'http://bc.grupodime.com.mx/api',
*/
  tokenName: 'authorization',
  conektaPublicKey: 'key_GJiafZrTCRjr2qS28S7rUrg',


  firebaseConfig: {
    apiKey: "AIzaSyACbiyDdVYNnsjsAu7eQYEEvsNg3ASIqm4",
    authDomain: "contrato-ts.firebaseapp.com",
    databaseURL: "https://contrato-ts.firebaseio.com",
    projectId: "contrato-ts",
    storageBucket: "contrato-ts.appspot.com",
    messagingSenderId: "1034893064215",
    appId: "1:1034893064215:web:3058a39836f6280a728452",
    measurementId: "G-9MX0KPWQVX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
