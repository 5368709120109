import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { UsuarioService } from 'app/main/administracion/usuarios/services/usuario.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { PasswordComponent } from 'app/main/administracion/usuarios/componentes/password/password.component';
import { Empresa } from 'app/main/administracion/empresa/models/empresa.model';
import { SetEmpresaAction } from 'app/main/administracion/empresa/reducers/empresa.accions';
import { Router } from '@angular/router';
import { SetUserProfileAction } from 'app/main/administracion/usuarios/reducers/usuario_profile.accions';
import { Aplicacion } from 'app/models/aplicacion.model';
import { AppService } from 'app/app.service';
import {UsuarioProfileService} from "../../../main/administracion/usuarios/services/usuarioProfile.service";


@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    usuario:Usuario=new Usuario('','','');
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    empresa : Empresa = new Empresa();
    userStatusOptions: any[];
    appSubscriber : Subscription;
    // Private
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private usuarioProfileService: UsuarioProfileService,
        public dialog: MatDialog,
        public store: Store<AppState>,
        public router: Router,
        public appService: AppService,
    )
    {

        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'es',
                title: 'Español',
                flag : 'mx'
            }
        ];
        this.navigation = navigation;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void
    {
        this.appSubscriber = this.appService.crearSubcriber().
        subscribe((data)=>{

            //console.log(data);
            this.usuario = data?.usuario;
            //console.log(this.usuario);


        });


        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    logOut(){
        this.usuarioProfileService.logOut();
       // this.usuarioService.logoutService();
        this.router.navigate(['/login']);
    }
    openDialog() {
        const dialogRef = this.dialog.open(PasswordComponent);

        dialogRef.afterClosed().subscribe(result => {
        });
      }

    gotoProfile(){
        this.router.navigate(['/pages/profile/'+this.usuario._id]);
    }


    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.appSubscriber.unsubscribe();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}

