import {Action} from '@ngrx/store';
import { Filtro } from 'app/models/filtro.model';
import { ControlEscolarApp } from 'app/models/controlEscolarApp.model';
import { UserPaginador } from 'app/main/controlEscolar/estudiantes/model/userPaginador.model';
import { GrupoPaginador } from 'app/main/controlEscolar/grupo/models/grupoPaginador.model';
import { NotificacionPaginador } from 'app/main/controlEscolar/notificaciones/models/notificacionPaginador.model';
import { ProgramaPaginador } from 'app/main/controlEscolar/programas/modelos/programPaginador.model';


export const SET_CE_ESTUDIANTES = '[Estudiante] Set Estudiante';
export const QUIT_CE_ESTUDIANTES = '[Estudiante] Quit Estudiante';

export const SET_CE_MAESTROS = '[Maestros] Set Maestros';
export const QUIT_CE_MAESTROS  = '[Maestros] Quit Maestros';

export const SET_CE_NOTIFICACIONES = '[Notificacion] Set Notificacion';
export const QUIT_CE_NOTIFICACIONES = '[Notificacion] Quit Notificacion';

export const SET_CE_GRUPOS= '[Grupo] Set Grupo';
export const QUIT_CE_GRUPOS = '[Grupo] Quit Grupo';

export const SET_CE_PROGRAMAS= '[Programas] Set Programas';
export const QUIT_CE_PROGRAMAS = '[Programas] Quit Programas';

export const SET_CONTROL_ESCOLAR = '[ControlEscolarApp] Set ControlEscolarApp';
export const QUIT_CONTROL_ESCOLAR = '[ControlEscolarApp] Quit ControlEscolarApp';

export const SET_CONTROL_ESCOLAR_FILTROS = '[ControlEscolarFiltro] Set ControlEscolarFiltro';
export const QUIT_CONTROL_ESCOLAR_FILTROS = '[ControlEscolarFiltro] Quit ControlEscolarFiltro';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetControlEscolarAppAction implements Action{
    readonly type = SET_CONTROL_ESCOLAR;

    constructor (public  controlEscolarApp : ControlEscolarApp ){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitControlEscolarAppAction implements Action{
    readonly type = QUIT_CONTROL_ESCOLAR;

    constructor (public  controlEscolarApp : ControlEscolarApp ){
        //localStorage.removeItem('administracionApp');
    }
}

// ESTUDIANTE ---------------------------------------------------------------------------------------------------------
export class SetEstudiantesControlEscolarAction implements Action{
    readonly type = SET_CE_ESTUDIANTES;

    constructor (public userPaginador : UserPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitEstudiantesControlEscolarAction implements Action{
    readonly type = QUIT_CE_ESTUDIANTES;

    constructor (public userPaginador : UserPaginador){
        //localStorage.removeItem('administracionApp');
    }
}

// MAESTRO ---------------------------------------------------------------------------------------------------------
export class SetMaestrosControlEscolarAction implements Action{
    readonly type = SET_CE_MAESTROS;

    constructor (public userPaginador : UserPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitMaestrosControlEscolarAction implements Action{
    readonly type = QUIT_CE_MAESTROS;

    constructor (public userPaginador : UserPaginador){
        //localStorage.removeItem('administracionApp');
    }
}

// GRUPO ---------------------------------------------------------------------------------------------------------
export class SetGruposControlEscolarAction implements Action{
    readonly type = SET_CE_GRUPOS;

    constructor (public grupoPaginador : GrupoPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitGruposControlEscolarAction implements Action{
    readonly type = QUIT_CE_GRUPOS;

    constructor (public grupoPaginador : GrupoPaginador){
        //localStorage.removeItem('administracionApp');
    }
}

// NOTIFICACION ---------------------------------------------------------------------------------------------------------
export class SetNotificacionEscolarAction implements Action{
    readonly type = SET_CE_NOTIFICACIONES;

    constructor (public notificacionPaginador : NotificacionPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitNotificacionControlEscolarAction implements Action{
    readonly type = QUIT_CE_NOTIFICACIONES;

    constructor (public notificacionPaginador : NotificacionPaginador){
        //localStorage.removeItem('administracionApp');
    }
}


// PROGRAMA ---------------------------------------------------------------------------------------------------------
export class SetProgramaEscolarAction implements Action{
    readonly type = SET_CE_PROGRAMAS;

    constructor (public programaPaginador : ProgramaPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitProgramaControlEscolarAction implements Action{
    readonly type = QUIT_CE_PROGRAMAS;

    constructor (public programaPaginador : ProgramaPaginador){
        //localStorage.removeItem('administracionApp');
    }
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroControlEscolarAction implements Action{
    readonly type = SET_CONTROL_ESCOLAR_FILTROS;

    constructor (public filtro : Filtro){
    }
}
export class QuitFiltroControlEscolarAction implements Action{
    readonly type = QUIT_CONTROL_ESCOLAR_FILTROS;

    constructor (public filtro : Filtro){
    }
}
export type acciones= SetControlEscolarAppAction  |  QuitControlEscolarAppAction
| SetEstudiantesControlEscolarAction | QuitEstudiantesControlEscolarAction | SetFiltroControlEscolarAction | QuitFiltroControlEscolarAction
| SetMaestrosControlEscolarAction | QuitMaestrosControlEscolarAction | SetGruposControlEscolarAction | QuitGruposControlEscolarAction |
SetNotificacionEscolarAction | QuitNotificacionControlEscolarAction | SetProgramaEscolarAction | QuitProgramaControlEscolarAction ;
