import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { AppState } from 'app/app.reducer';
import { ContratosApp } from 'app/models/contratosApp.model';
import { Filtro } from 'app/models/filtro.model';
import { SetContratoAction  ,  QuitContratoAction , SetIngresoAction ,  QuitIngresoAppAction
        , SetEgresoAction ,  QuitEgresoAppAction , SetComplejoAction , QuitComplejoAction , SetDepartamentoAction
        , QuitDepartamentoAction , SetCasaAction , QuitCasaAction , SetPredioAction , QuitPredioAction
        , SetContratoAppAction , QuitContratoAppAction , SetFiltroContratosAction , QuitFiltroContratosAction
        , SetContratoModelAction , QuitContratoModelAction , SetIngresoModelAction , QuitIngresoAppModelAction
        , SetEgresoModelAction , QuitEgresoAppModelAction , SetComplejoModelAction , QuitComplejoModelAction
        , SetDepartamentoModelAction , QuitDepartamentoModelAction , SetCasaModelAction , QuitCasaModelAction
        , SetPredioModelAction , QuitPredioModelAction
        } from 'app/services/reducers/contratosApp/contratosApp.accions';
import { Departamento } from './departamentos/model/departamento.model';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContratoPaginador } from './contrato/model/contratoPagina.model';
import { IngresoPaginador } from './ingresos/models/ingresoPaginador.model';
import { CasaPaginador } from './casas/model/casasPaginador.model';
import { DepartamentoPaginador } from './departamentos/model/departamentoPaginador.model';
import { PredioPaginador } from './predios/model/predioPaginador.model';
import { Contrato } from './contrato/model/contrato.model';
import { Ingreso } from './ingresos/models/ingresos.model';
import { Complejo } from './complejos/model/complejo.model';
import { Casa } from './casas/model/casas.model';
import { Predio } from './predios/model/predio.model';

@Injectable({
  providedIn: 'root'
})
export class Contratosv2Service implements Resolve<any> {

    contratosApp : ContratosApp = new ContratosApp();
    contrato : ContratoPaginador = new ContratoPaginador();
    departamento : Departamento = new Departamento();
    appSubscriber : Subscription;
    filtro : Filtro = new Filtro();

    constructor(
      private store: Store<AppState>
    ) {}

    crearSubcriber (){
      return this.store.select('contratosApp');
    }

    cambiarContrato ( contrato: ContratoPaginador ){
      this.store.dispatch(new SetContratoAction(contrato));
    }

    quitarContrato ( ){
      this.store.dispatch(new QuitContratoAction());
    }

    cambiarContratoModel ( contrato: Contrato){
      this.store.dispatch(new SetContratoModelAction(contrato));
    }

    quitarContratoModel ( ){
      this.store.dispatch(new QuitContratoModelAction());
    }

    cambiarIngreso ( ingreso: IngresoPaginador ){
      this.store.dispatch(new SetIngresoAction(ingreso));
    }

    quitarIngreso ( ){
      this.store.dispatch(new QuitIngresoAppAction());
    }

    cambiarIngresoModel ( ingreso: Ingreso ){
      this.store.dispatch(new SetIngresoModelAction(ingreso));
    }

    quitarIngresoModel ( ){
      this.store.dispatch(new QuitIngresoAppModelAction());
    }

    cambiarEgreso ( ingreso: IngresoPaginador ){
      this.store.dispatch(new SetEgresoAction(ingreso));
    }

    quitarEgreso ( ){
      this.store.dispatch(new QuitEgresoAppAction());
    }

    cambiarEgresoModel ( ingreso: Ingreso ){
      this.store.dispatch(new SetEgresoModelAction(ingreso));
    }

    quitarEgresoModel ( ){
      this.store.dispatch(new QuitEgresoAppModelAction());
    }

    cambiarComplejo ( complejo: Complejo ){
      this.store.dispatch(new SetComplejoAction(complejo));
    }

    quitarComplejo ( ){
      this.store.dispatch(new QuitComplejoAction());
    }

    cambiarComplejoModel ( complejo: Complejo ){
      this.store.dispatch(new SetComplejoModelAction(complejo));
    }

    quitarComplejoModel ( ){
      this.store.dispatch(new QuitComplejoModelAction());
    }

    cambiarFiltro ( filtro : Filtro ){
      this.store.dispatch(new SetFiltroContratosAction(filtro));
    }

    quitarFiltro ( ){
      this.store.dispatch(new QuitFiltroContratosAction());
    }

    cambiarCasa ( casa : CasaPaginador ){
      this.store.dispatch(new SetCasaAction(casa));
    }

    quitarCasa ( ){
      this.store.dispatch(new QuitCasaAction());
    }

    cambiarCasaModel ( casa : Casa ){
      this.store.dispatch(new SetCasaModelAction(casa));
    }

    quitarCasaModel ( ){
      this.store.dispatch(new QuitCasaModelAction());
    }

    cambiarDepartamento ( departamento : DepartamentoPaginador ){
      this.store.dispatch(new SetDepartamentoAction(departamento));
    }

    quitarDepartamento ( ){
      this.store.dispatch(new QuitDepartamentoAction());
    }

    cambiarDepartamentoModel ( departamento : Departamento ){
      this.store.dispatch(new SetDepartamentoModelAction(departamento));
    }

    quitarDepartamentoModel ( ){
      this.store.dispatch(new QuitDepartamentoModelAction());
    }

    cambiarPredio ( predio : PredioPaginador ){
      this.store.dispatch(new SetPredioAction(predio));
    }

    quitarPredio ( ){
      this.store.dispatch(new QuitPredioAction());
    }

    cambiarPredioModel ( predio : Predio ){
      this.store.dispatch(new SetPredioModelAction(predio));
    }

    quitarPredioModel ( ){
      this.store.dispatch(new QuitPredioModelAction());
    }

    setContratos ( contratosApp : ContratosApp ){
      this.store.dispatch(new SetContratoAppAction(contratosApp));
    }

    quitarContratosApp ( ){
      this.store.dispatch(new QuitContratoAppAction());
    }

    eliminarStore(){
        this.appSubscriber.unsubscribe();
    }

    cargarStorage(): Promise<any> {
      return new Promise ((resolve, reject ) =>{
        let contratos: ContratosApp = new ContratosApp();
        if (localStorage.getItem('contratosApp')) {
          contratos = JSON.parse(localStorage.getItem('contratosApp'));
        }
        if (!contratos.filtro){
          contratos.filtro = new Filtro ();
        }
        this.setContratos(contratos);
        resolve(contratos);
      });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.cargarStorage(), //  carga el ultimo estado del modulo de contratos
            ]).then(
                ([contratosApp]) => {
                    this.contratosApp = contratosApp;
                    resolve();
                },
                reject
            );
        });
    }

}
