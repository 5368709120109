import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.reducer';
import {URL_SERVICIOS} from 'app/config/config';
import {ImagenService} from '../../../../services/imagen/imagen.service';
import {ToastrService} from 'ngx-toastr';
import {Contrato} from 'app/main/contratosv2/contrato/model/contrato.model';
import {PaginadorTabla, PaginadorBusquedaTabla} from 'app/models/paginador.model';
import {Observable} from "rxjs";
import {Contratosv2Service} from "../../contratosv2.service";
import {ContratoPaginador} from "../model/contratoPagina.model";
import {Complejo} from "../../complejos/model/complejo.model";
import { Partner } from 'app/main/administracion/partner/models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class ContratoService implements Resolve<any> {
  routeParams: any;
  contrato: Contrato = new Contrato();
  complejos: Complejo[] = [];
  partners: Partner [];
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    public store: Store<AppState>,
    public imagenService: ImagenService,
    public toasterService: ToastrService,
    public contratoV2Service: Contratosv2Service
  ) {}

   obtenerPagos( paginador ) {
    //console.log(paginador);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/pagosPaginados/'+paginador.contratoId;
    return this.http.post(url,paginador, {headers});
  }

  obtenerPagosPorComplejo( paginador ) {
    //console.log(paginador);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/pagosPaginados';
    return this.http.post(url,paginador, {headers});
  }

  obtenerContrato(paginador: PaginadorBusquedaTabla) {
    //console.log('obtenerContrato::paginador:');
    //console.log(paginador);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/obtenerContratosTerrenosPaginados';
    return this.http.post(url, paginador, {headers});
  }

  obtenerContratoPorCliente(paginador: PaginadorTabla) {
    //console.log(paginador);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/obtenerContratosTerrenosPorClientePaginados';
    return this.http.post(url,paginador, {headers});
  }

  obtenerContratoId(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/obtenerContratoPorId/' + id;
    return this.http.get(url, {headers});
  }
  eliminarContrato(id: string)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    const url = URL_SERVICIOS + '/eliminarContratoTerreno/' + id;
    return this.http.delete(url, {headers});
  }
  agregarContrato(contrato: Contrato){
    //console.log(contrato);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    });
    let url = URL_SERVICIOS + '/crearContratoTerreno';
    if (contrato._id){
      //console.log('TeINE ID');
      url += '/' + contrato._id;
      return this.http.put(url, contrato , {headers})
    }
    else{
      //console.log('NO TIENE ID');
      return this.http.post(url, contrato , {headers})
    }
  }
  obtenerUsuarios(filtro) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token'),
    });

    //filtro['busqueda'] = busqueda;

    let url = URL_SERVICIOS + '/usuariosPorRole/';
    return this.http.post(url, filtro,{headers});
  }

  /**
   * Get contrato
   *
   * @returns {Promise<any>}
   */
  getContrato(): Promise<any>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      if('new' === this.routeParams.id){
        resolve(this.contrato = new Contrato());
      }else{
        this.http.get(URL_SERVICIOS + '/obtenerContratoPorId/' + this.routeParams.id, {headers})
          .subscribe((response: any) => {
            this.contrato = response.contrato;
            this.contratoV2Service.cambiarContratoModel(response.contrato);
            resolve(response.contrato);
          }, reject);
      }
    });
  }

  /**
   * Get Complejos
   *
   * @returns {Promise<any>}
   */
  getComplejos(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      this.http.get(URL_SERVICIOS + '/obtenerComplejosSinPaginar', {headers})
        .subscribe((response: any) => {
          this.complejos = response.complejo;

          resolve(response.complejo);
        }, reject);
    });
  }

    /**
   * Get Partners
   *
   * @returns {Promise<any>}
   */
  getPartners(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      this.http.get(URL_SERVICIOS + '/partner', {headers})
        .subscribe((response: any) => {
          //console.log(response.partner);
          this.partners = response.partner;

          resolve(response.partner);
        }, reject);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getContrato(),
        this.getComplejos(),
        this.getPartners(),
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }


}
