import { ConfiguracionesApp, ConfiguracionesState } from 'app/models/configuracionesApp.model';
import * as fromConfiguracion from './configuracion.accions';

const estadoInicial: ConfiguracionesApp = new ConfiguracionesApp();

export function ConfiguracionReducer(state = estadoInicial,action:fromConfiguracion.acciones):ConfiguracionesState{
    switch(action.type){

      //   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
      case fromConfiguracion.SET_CONFIGURACION_APP:
        let configuracionApp = { ...action.configuracionesApp };
        localStorage.setItem('configuracionesApp', JSON.stringify(configuracionApp));
        return configuracionApp
      case fromConfiguracion.QUIT_CONFIGURACION_APP:
          return new ConfiguracionesApp();

      //   CONFIGURACIONES PAGINADOR---------------------------------------------------------------------------------------------------------
      case fromConfiguracion.SET_CONFIGURACION:
        let nuevoEstadoConfiguracion = { ...state };
        nuevoEstadoConfiguracion.configuraciones = { ...action.configuracion };
        localStorage.setItem('configuracionesApp', JSON.stringify(nuevoEstadoConfiguracion));
        return nuevoEstadoConfiguracion;
      case fromConfiguracion.QUIT_CONFIGURACION:
        localStorage.removeItem('configuracionesApp');
        let quitConfiguracion = { ...state };
        quitConfiguracion.configuraciones.paginador = null;
        return quitConfiguracion;

      //   CONFIGURACIONES MODEL---------------------------------------------------------------------------------------------------------
      case fromConfiguracion.SET_CONFIGURACION_MODEL:
        let nuevoEstadoConfiguracionModel = { ...state };
        nuevoEstadoConfiguracionModel.configuraciones.configuracion = { ...action.configuracion };
        localStorage.setItem('configuracionesAppModel', JSON.stringify(nuevoEstadoConfiguracionModel));
        return nuevoEstadoConfiguracionModel;
      case fromConfiguracion.QUIT_CONFIGURACION_MODEL:
        localStorage.removeItem('configuracionesAppModel');
        let quitConfiguracionModel = { ...state };
        quitConfiguracionModel.configuraciones.configuracion = null;
        return quitConfiguracionModel;

      default:
        return state;
    }
}
