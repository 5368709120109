import { FuseNavigation } from '@fuse/types';
import { UsuarioService } from 'app/main/administracion/usuarios/services/usuario.service';



export class Menu{
    menu:any[]=[];
    constructor(public usuarioService:UsuarioService,
       ) {
        
       }
    cargarMenu() {
    this.menu= this.usuarioService.menu;
    }
    

}
export const navigation: FuseNavigation[] = [
];