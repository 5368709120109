import { PaginadorTabla } from './models/paginador.model';
import { Injectable } from '@angular/core';
import { Aplicacion } from './models/aplicacion.model';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducer';
import { Usuario } from './main/administracion/usuarios/models/usuario.model';
import { SetAplicacionUserAction,
  QuitAplicacionUserAction,
  SetAplicacionAction,
  SetAplicacionPaginadorAction,
  QuitAplicacionPaginadorAction,
  SetAplicacionFiltroAction,
  QuitAplicacionFiltroAction,
  SetAplicacionCurrentModelEditAction,
  QuitAplicacionCurrentModelEditAction
} from './services/reducers/aplicacion/aplicacion.accions';
import { Partner } from './main/administracion/partner/models/partner.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from './config/config';
import { Filtro } from './models/filtro.model';

@Injectable({
  providedIn: 'root'
})
export class AppService   {


  app : Aplicacion = new Aplicacion();


  constructor(
    private store: Store<AppState>,
    public http: HttpClient,
  ) {}

  crearSubcriber (){
    return this.store.select('app');
  }

  cambiarUsuario ( nuevoUsuario: Usuario ){
    let tmp = {...nuevoUsuario}

    this.store.dispatch(new SetAplicacionUserAction(tmp));
  }

  cambiarPartner ( partner: Partner ){
    let tmp = {...partner}
    this.store.dispatch(new SetAplicacionUserAction(tmp));
  }

  quitarUsuario(usuario : Usuario){
    this.store.dispatch(new QuitAplicacionUserAction(usuario));
  }

  cambiarPaginador ( paginador: PaginadorTabla ){
    this.store.dispatch(new SetAplicacionPaginadorAction(paginador));
  }

  quitarPaginador (){
    this.store.dispatch(new QuitAplicacionPaginadorAction());
  }

  cambiarFiltro ( filtro: Filtro ){
    this.store.dispatch(new SetAplicacionFiltroAction(filtro));
  }

  quitarFiltro (){
    this.store.dispatch(new QuitAplicacionFiltroAction());
  }



  cambiarModeloEditar ( modelo: any ){
    this.store.dispatch(new SetAplicacionCurrentModelEditAction(modelo));
  }

  quitarModeloEditar (){
    this.store.dispatch(new QuitAplicacionCurrentModelEditAction());
  }

  cargarStorage() {
    if (localStorage.getItem('aplicacion')) {
      this.app = JSON.parse(localStorage.getItem('aplicacion'));
      this.store.dispatch(new SetAplicacionAction(this.app));
      //console.log(this.app);
    } else {
      this.app = null;
    }
  }

  eliminarStore(){
    //localStorage.clear();
  }

  compruebaRuta(data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      let url = URL_SERVICIOS + '/comprobarAccesoRuta/';
      this.http.post(url, data,{headers})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}
