import { MenuChildren } from './../../../main/administracion/menu/models/menuChildren.model';
import { SubCategoria } from './../../../main/administracion/categoria/model/subCategoria.model';
import { SubCategoriaPaginador } from './../../../main/administracion/categoria/model/subCategoriaPaginador.model';
import {Action} from '@ngrx/store';
import { AdministracionApp } from 'app/models/administracionApp.model';
import { EmpresaPaginador } from 'app/main/administracion/empresa/models/empresaPaginador.model';
import { UsuarioPaginador } from 'app/main/administracion/usuarios/models/usuarioPaginador.model';
import { PartnerPaginador } from 'app/main/administracion/partner/models/partnerPaginador.model';
import { CategoriaPaginador } from 'app/main/administracion/categoria/model/categoriaPaginador.model';
import { Filtro } from 'app/models/filtro.model';
import { GrupoPaginador } from 'app/main/administracion/grupo/models/grupoPaginador.model';
import { Categoria } from 'app/main/administracion/categoria/model/categoria.model';
import { Empresa } from 'app/main/administracion/empresa/models/empresa.model';
import { Grupo } from 'app/main/administracion/grupo/models/grupo.model';
import { Partner } from 'app/main/administracion/partner/models/partner.model';
import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { RolePaginador } from 'app/main/administracion/role/models/rolePaginador.model';
import { Role } from 'app/main/administracion/role/models/role.model';
import { MenuPaginador } from 'app/main/administracion/menu/models/menuPaginador.model';
import { Menu } from 'app/main/administracion/menu/models/menu.model';
import { PaginadorTabla } from 'app/models/paginador.model';


export const SET_CATEGORIA = '[Categoria] Set Categoria';
export const QUIT_CATEGORIA = '[Categoria] Quit Categoria';

export const SET_CATEGORIA_MODEL = '[CategoriaModel] Set CategoriaModel';
export const QUIT_CATEGORIA_MODEL = '[CategoriaModel] Quit CategoriaModel';

export const SET_SUBCATEGORIA = '[SubCategoria] Set SubCategoria';
export const QUIT_SUBCATEGORIA = '[SubCategoria] Quit SubCategoria';

export const SET_SUBCATEGORIA_MODEL = '[SubCategoriaModel] Set SubCategoriaModel';
export const QUIT_SUBCATEGORIA_MODEL = '[SubCategoriaModel] Quit SubCategoriaModel';

export const SET_EMPRESA = '[Empresa] Set Empresa';
export const QUIT_EMPRESA = '[Empresa] Quit Empresa';

export const SET_EMPRESA_MODEL = '[EmpresaModel] Set EmpresaModel';
export const QUIT_EMPRESA_MODEL = '[EmpresaModel] Quit EmpresaModel';

export const SET_GRUPO = '[Grupo] Set Grupo';
export const QUIT_GRUPO = '[Grupo] Quit Grupo';

export const SET_GRUPO_MODEL = '[GrupoModel] Set GrupoModel';
export const QUIT_GRUPO_MODEL = '[GrupoModel] Quit GrupoModel';

export const SET_PARTNER = '[Partner] Set Partner';
export const QUIT_PARTNER = '[Partner] Quit Partner';

export const SET_PARTNER_MODEL = '[PartnerModel] Set PartnerModel';
export const QUIT_PARTNER_MODEL = '[PartnerModel] Quit PartnerModel';

export const SET_USUARIO = '[Usuario] Set Usuario';
export const QUIT_USUARIO  = '[Usuario] Quit Usuario';


export const SET_USUARIO_MODEL = '[UsuarioModel] Set UsuarioModel';
export const QUIT_USUARIO_MODEL  = '[UsuarioModel] Quit UsuarioModel';

export const SET_ROLE = '[Role] Set Role';
export const QUIT_ROLE  = '[Role] Quit Role';

export const SET_ROLE_MODEL = '[RoleModel] Set RoleModel';
export const QUIT_ROLE_MODEL  = '[RoleModel] Quit RoleModel';

export const SET_MENU = '[MenuAdmin] Set MenuAdmin';
export const QUIT_MENU  = '[MenuAdmin] Quit MenuAdmin';

export const SET_MENU_MODEL = '[MenuModel] Set MenuModel';
export const QUIT_MENU_MODEL  = '[MenuModel] Quit MenuModel';

export const SET_MENU_CHILDREN = '[MenuChildren] Set MenuChildren';
export const QUIT_MENU_CHILDREN = '[MenuChildren] Quit MenuChildren';

export const SET_ADMINISTRACIONAPP = '[AdministracionApp] Set AdministracionApp';
export const QUIT_ADMINISTRACIONAPP = '[AdministracionApp] Quit AdministracionApp';

export const SET_ADMINISTRACIONFILTROS = '[AdministracionFiltros] Set AdministracionFiltros';
export const QUIT_ADMINISTRACIONFILTROS = '[AdministracionFiltros] Quit AdministracionFiltros';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetAdministracionAppAction implements Action{
  readonly type = SET_ADMINISTRACIONAPP;
  constructor (public administracionApp : AdministracionApp){}
}
export class QuitdministracionAppAction implements Action{
  readonly type = QUIT_ADMINISTRACIONAPP;
  constructor (){}
}

//   CATEGORIA ---------------------------------------------------------------------------------------------------------
export class SetCategoriaAction implements Action{
  readonly type = SET_CATEGORIA;
  constructor (public categoria : CategoriaPaginador){}
}
export class QuitCategoriaAction implements Action{
  readonly type = QUIT_CATEGORIA;
  constructor (){}
}

//   CATEGORIA MODEL---------------------------------------------------------------------------------------------------------
export class SetCategoriaModelAction implements Action{
  readonly type = SET_CATEGORIA_MODEL;
  constructor (public categoria : Categoria){}
}
export class QuitCategoriaModelAction implements Action{
  readonly type = QUIT_CATEGORIA_MODEL;
  constructor (){}
}

//   SUBCATEGORIA ---------------------------------------------------------------------------------------------------------
export class SetSubCategoriaAction implements Action{
  readonly type = SET_SUBCATEGORIA;
  constructor (public subCategoria : SubCategoriaPaginador){}
}
export class QuitSubCategoriaAction implements Action{
  readonly type = QUIT_SUBCATEGORIA;
  constructor (){}
}

//   SUBCATEGORIA MODEL---------------------------------------------------------------------------------------------------------
export class SetSubCategoriaModelAction implements Action{
  readonly type = SET_SUBCATEGORIA_MODEL;
  constructor (public subCategoria : SubCategoria){}
}
export class QuitSubCategoriaModelAction implements Action{
  readonly type = QUIT_SUBCATEGORIA_MODEL;
  constructor (){}
}

// EMPRESA ---------------------------------------------------------------------------------------------------------
export class SetEmpresaAction implements Action{
  readonly type = SET_EMPRESA;
  constructor (public empresa : EmpresaPaginador){}
}
export class QuitEmpresaAction implements Action{
  readonly type = QUIT_EMPRESA;
  constructor (){}
}

// EMPRESA MODEL---------------------------------------------------------------------------------------------------------
export class SetEmpresaModelAction implements Action{
  readonly type = SET_EMPRESA_MODEL;
  constructor (public empresa : Empresa){}
}
export class QuitEmpresaModelAction implements Action{
  readonly type = QUIT_EMPRESA_MODEL;
  constructor (){}
}

// GRUPO ---------------------------------------------------------------------------------------------------------
export class SetGrupoAction implements Action{
  readonly type = SET_GRUPO;
  constructor (public grupo : GrupoPaginador){}
}
export class QuitGrupoAction implements Action{
  readonly type = QUIT_GRUPO;
  constructor (){}
}

// GRUPO MODEL---------------------------------------------------------------------------------------------------------
export class SetGrupoModelAction implements Action{
  readonly type = SET_GRUPO_MODEL;
  constructor (public grupo : Grupo){}
}
export class QuitGrupoModelAction implements Action{
  readonly type = QUIT_GRUPO_MODEL;
  constructor (){}
}

// PARTNER ---------------------------------------------------------------------------------------------------------
export class SetPartnerAction implements Action{
  readonly type = SET_PARTNER;
  constructor (public partner : PartnerPaginador){}
}
export class QuitPartnerAction implements Action{
  readonly type = QUIT_PARTNER;
  constructor (){}
}

// PARTNER MODEL---------------------------------------------------------------------------------------------------------
export class SetPartnerModelAction implements Action{
  readonly type = SET_PARTNER_MODEL;
  constructor (public partner : Partner){}
}
export class QuitPartnerModelAction implements Action{
  readonly type = QUIT_PARTNER_MODEL;
  constructor (){}
}

// USUARIO ---------------------------------------------------------------------------------------------------------
export class SetUsuarioAction implements Action{
  readonly type = SET_USUARIO;
  constructor (public usuario : UsuarioPaginador){}
}

export class QuitUsuarioAction implements Action{
  readonly type = QUIT_USUARIO;
  constructor (){}
}

//PAGINACION
export class SetPaginacion implements Action{
  readonly type = SET_USUARIO;
  constructor (public paginador : PaginadorTabla){}
}

// USUARIO MODEL---------------------------------------------------------------------------------------------------------
export class SetUsuarioModelAction implements Action{
  readonly type = SET_USUARIO_MODEL;
  constructor (public usuario : Usuario){}
}
export class QuitUsuarioModelAction implements Action{
  readonly type = QUIT_USUARIO_MODEL;
  constructor (){}
}

// ROLE ---------------------------------------------------------------------------------------------------------
export class SetRoleAction implements Action{
  readonly type = SET_ROLE;
  constructor (public role : RolePaginador){}
}
export class QuitRoleAction implements Action{
  readonly type = QUIT_ROLE;
  constructor (){}
}

// ROLE MODEL---------------------------------------------------------------------------------------------------------
export class SetRoleModelAction implements Action{
  readonly type = SET_ROLE_MODEL;
  constructor (public role : Role){}
}
export class QuitRoleModelAction implements Action{
  readonly type = QUIT_ROLE_MODEL;
  constructor (){}
}

// MENU ---------------------------------------------------------------------------------------------------------
export class SetMenuAction implements Action{
  readonly type = SET_MENU;
  constructor (public menu : MenuPaginador){}
}
export class QuitMenuAction implements Action{
  readonly type = QUIT_MENU;
  constructor (){}
}

// MENU MODEL---------------------------------------------------------------------------------------------------------
export class SetMenuModelAction implements Action{
  readonly type = SET_MENU_MODEL;
  constructor (public menu : Menu){}
}
export class QuitMenuModelAction implements Action{
  readonly type = QUIT_MENU_MODEL;
  constructor (){}
}

// MENU CHILDREN---------------------------------------------------------------------------------------------------------
export class SetMenuChildrenAction implements Action{
  readonly type = SET_MENU_CHILDREN;
  constructor (public menuChildren : MenuChildren){}
}
export class QuitMenuChildrenAction implements Action{
  readonly type = QUIT_MENU_CHILDREN;
  constructor (){}
}

export type acciones = SetAdministracionAppAction  |  QuitdministracionAppAction
  | SetCategoriaAction | QuitCategoriaAction | SetEmpresaAction |  QuitEmpresaAction | SetGrupoAction | QuitGrupoAction
  | SetPartnerAction | QuitPartnerAction | SetUsuarioAction | QuitUsuarioAction
  | SetSubCategoriaAction | QuitSubCategoriaAction | SetCategoriaModelAction | QuitCategoriaModelAction | SetSubCategoriaModelAction
  | QuitSubCategoriaModelAction | SetEmpresaModelAction | QuitEmpresaModelAction | SetGrupoModelAction | QuitGrupoModelAction
  | SetPartnerModelAction | QuitPartnerModelAction | SetUsuarioModelAction | QuitUsuarioModelAction | SetSubCategoriaAction | QuitSubCategoriaAction
  | SetRoleAction | QuitRoleAction | SetRoleModelAction | QuitRoleModelAction | SetMenuAction | QuitMenuAction | SetMenuModelAction | QuitMenuModelAction
  | SetMenuChildrenAction | QuitMenuChildrenAction ;
