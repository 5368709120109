import {Action} from '@ngrx/store';
import { Filtro } from 'app/models/filtro.model';
import { ProductoPaginador } from 'app/main/productos/producto/models/productoPaginador.model';
import { ProductoApp } from 'app/models/productosApp.model';
import { Producto } from 'app/main/productos/producto/models/producto.model';

export const SET_PRODUCTO = '[Producto] Set Producto';
export const QUIT_PRODUCTO  = '[Producto] Quit Producto';

export const SET_PRODUCTO_MODEL = '[ProductoModel] Set ProductoModel';
export const QUIT_PRODUCTO_MODEL  = '[ProductoModel] Quit ProductoModel';

export const SET_PRODUCTOAPP = '[ProductoApp] Set ProductoApp';
export const QUIT_PRODUCTOAPP = '[ProductoApp] Quit ProductoApp';

export const SET_PRODUCTOAPPFILTROS = '[ProductoAppFiltro] Set ProductoAppFiltro';
export const QUIT_PRODUCTOAPPFILTROS = '[ProductoAppFiltro] Quit ProductoAppFiltro';

//   ADMINSITRACION ---------------------------------------------------------------------------------------------------------
export class SetProductoAppAction implements Action{
    readonly type = SET_PRODUCTOAPP;

    constructor (public  productoApp : ProductoApp ){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitProcuctoAppAction implements Action{
    readonly type = QUIT_PRODUCTOAPP;

    constructor (){
        //localStorage.removeItem('administracionApp');
    }
}

// PRODUCTO ---------------------------------------------------------------------------------------------------------
export class SetProductoAction implements Action{
    readonly type = SET_PRODUCTO;

    constructor (public producto : ProductoPaginador){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitProductoAction implements Action{
    readonly type = QUIT_PRODUCTO;

    constructor (){
        //localStorage.removeItem('administracionApp');
    }
}

// PRODUCTO ---------------------------------------------------------------------------------------------------------
export class SetProductoModelAction implements Action{
    readonly type = SET_PRODUCTO_MODEL;

    constructor (public producto : Producto){
        //localStorage.setItem('administracionApp', JSON.stringify(administracionApp));
    }
}
export class QuitProductoModelAction implements Action{
    readonly type = QUIT_PRODUCTO_MODEL;

    constructor (){
        //localStorage.removeItem('administracionApp');
    }
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroProductoAction implements Action{
    readonly type = SET_PRODUCTOAPPFILTROS;

    constructor (public filtro : Filtro){
    }
}
export class QuitFiltroProductoAction implements Action{
    readonly type = QUIT_PRODUCTOAPPFILTROS;

    constructor (){
    }
}
export type acciones= SetProductoAppAction  |  QuitProcuctoAppAction
| SetProductoAction | QuitProductoAction | SetFiltroProductoAction 
| QuitFiltroProductoAction | SetProductoModelAction | QuitProductoModelAction;
