import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'role'
  })
export class RolePipe implements PipeTransform {

    transform(tipo: string= 'Sin rol'): any {

      switch (tipo)
      {
        case 'ADMIN_ROLE':
           return 'Administrador'
           break;
        case 'USER_SALE':
          return 'Vendedor'
              break;
        case 'USER_ROOT':
          return 'Root'
          break;
        case 'ROLE_USER':
          return 'Usuario'
          break;
          case 'USER_SALE_CONTRACT':
            return 'Vendedor C'
            break;
        case 'ROLE_SALE_PROMOTOR':
          return 'Vendedor promotoria'
           break;
        case 'ROLE_COACH':
          return 'Coach'
          break;

        default:
          return 'Sin Rol'
        }
    }
  }

