import { MenuChildren } from './../main/administracion/menu/models/menuChildren.model';
import { SubCategoriaPaginador } from './../main/administracion/categoria/model/subCategoriaPaginador.model';
import { Filtro } from './filtro.model';
import { EmpresaPaginador } from 'app/main/administracion/empresa/models/empresaPaginador.model';
import { UsuarioPaginador } from 'app/main/administracion/usuarios/models/usuarioPaginador.model';
import { PartnerPaginador } from 'app/main/administracion/partner/models/partnerPaginador.model';
import { CategoriaPaginador } from 'app/main/administracion/categoria/model/categoriaPaginador.model';
import { GrupoPaginador } from 'app/main/administracion/grupo/models/grupoPaginador.model';
import { RolePaginador } from 'app/main/administracion/role/models/rolePaginador.model';
import { MenuPaginador } from 'app/main/administracion/menu/models/menuPaginador.model';



export interface AdministracionAppState{



  categorias ?: CategoriaPaginador,
  subCategorias ?: SubCategoriaPaginador,
  empresas ?: EmpresaPaginador,
  grupos ?: GrupoPaginador,
  partner ?: PartnerPaginador,
  usuarios ?: UsuarioPaginador,
  roles ?: RolePaginador,
  menus ?: MenuPaginador,
  menuChildren ?: MenuChildren,
  filtro ?: Filtro,
  //paginacion ?: Paginacion,
}



export class AdministracionApp implements AdministracionAppState{
  constructor(
    public categorias ?: CategoriaPaginador,
    public subCategorias ?: SubCategoriaPaginador,
    public empresas ?: EmpresaPaginador,
    public grupos ?: GrupoPaginador,
    public partner ?: PartnerPaginador,
    public usuarios ?: UsuarioPaginador,
    public roles ?: RolePaginador,
    public menus ?: MenuPaginador,
    public menuChildren ?: MenuChildren,
    public filtro ?: Filtro,
  ){}
}
