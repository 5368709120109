import {Contrato} from './contrato.model';
import {PaginadorTabla} from 'app/models/paginador.model';


export class ContratoPaginador
{
    constructor(
        public contrato ?: Contrato,
        public paginador ?: PaginadorTabla,

        )
    {

    }

}
