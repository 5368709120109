import { ConfiguracionPaginador } from './../main/configuracion/models/configuracionPaginador.model';

export interface ConfiguracionesState{
  configuraciones ?: ConfiguracionPaginador,
}

export class ConfiguracionesApp implements ConfiguracionesState{
    constructor(
      public configuraciones ?: ConfiguracionPaginador,
    ){}
}
