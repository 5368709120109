import { ConfiguracionesAppService } from './main/configuracion/configuracionesApp.service';
import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginGuardsGuard } from './services/guards/login-guards.guard';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmDirectionModule } from 'agm-direction';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from './services/interceptors/interceptor.service';
import { registerLocaleData, CurrencyPipe, DatePipe } from '@angular/common';
import locale_esMX from '@angular/common/locales/es-MX';
import { Contratosv2Service } from './main/contratosv2/contratosv2.service';
import { AdministracionAppService } from './main/administracion/administracion.service';
import {UsuarioProfileService} from "./main/administracion/usuarios/services/usuarioProfile.service";
import { InversionesService } from './main/inversiones/inversiones.service';
import { ControlEscolarService } from './main/controlEscolar/controlEscolar.service';
import {CotizadorService} from "./main/pages/cotizador-material/cotizador.service";
import { AngularFireModule } from '@angular/fire';
import { TarjetasAppService } from './main/tarjetas/tarjetasApp.service';
import { AvatarModule } from 'ngx-avatar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {AccessibleRoute} from './services/guards/accessibleRoute.guard';

const appRoutes: Routes = [

     {
        path: 'pages',
         loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
         canActivate: [LoginGuardsGuard],
       canActivateChild: [ AccessibleRoute]
    },
    {
        path: 'administracion',
        loadChildren: () => import('./main/administracion/administracion.module').then(m => m.AdministracionModule),
        resolve  : {
            administracion: AdministracionAppService
        },
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path: 'tarjetas',
        loadChildren: () => import('./main/tarjetas/tarjetas.module').then(m => m.TarjetasModule),
        resolve  : {
            administracion: TarjetasAppService
        },
        canActivate: [LoginGuardsGuard],
        canActivateChild: [ AccessibleRoute]
    },
    {
        path: 'configuraciones',
        loadChildren: () => import('./main/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        resolve  : {
            administracion: ConfiguracionesAppService
        },
        canActivate: [LoginGuardsGuard]
    },
    {
        path: 'contratos',
        loadChildren: () => import('./main/contratosv2/contratosv2.module').then(m => m.Contratosv2Module),
        resolve  : {
            contratos: Contratosv2Service
        },
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path: 'contrato',
        loadChildren: () => import('./main/contrato/contrato.module').then(m => m.ContratoModule),
        // resolve  : {
        //     contratos: Contratosv2Service
        // },
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path        : 'productos',
        loadChildren: () => import('./main/e-commerce/e-commerce.module').then(m => m.EcommerceModule),
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path        : 'inversiones',
        loadChildren: () => import('./main/inversiones/inversiones.module').then(m => m.InversionesModule),
        resolve  : {
            prodctosFinancieros: InversionesService
        },
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path        : 'controlEscolar',
        loadChildren: () => import('./main/controlEscolar/controlEscolar.module').then(m => m.ControlEscolarModule),
        resolve  : {
            controlEscolarService: ControlEscolarService
        },
        canActivate: [LoginGuardsGuard],
      canActivateChild: [ AccessibleRoute]
    },
    {
        path: 'login',
        loadChildren: () => import('./main/pages/authentication/login/login.module').then(m => m.LoginModule),
    },
     {
        path: 'register',
        loadChildren: () => import('./main/pages/authentication/register/register.module').then(m => m.RegisterModule),
    },

    {
      path: 'encuesta',
      loadChildren: () => import('./main/pages/encuesta/encuesta.module').then(m => m.EncuestaModule),
    },

    {
      path: 'cotizador',
      loadChildren: () => import('./main/pages/cotizador-material/cotizador.module').then(m => m.CotizadorModule),
      resolve  : {
        cotizadorService: CotizadorService
      },
    },
    {
      path: '**',
      redirectTo: 'login'
    }
];

registerLocaleData(locale_esMX, 'es-MX');


@NgModule({
    declarations: [
        AppComponent,

        //DialogContentExampleDialog
    ],
    entryComponents: [
        //DialogContentExampleDialog
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        //RouterModule.forRoot(appRoutes, { enableTracing: true }),

        TranslateModule.forRoot(),
        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AvatarModule,

        //MAPS
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDyJPphaC4_NbvJEJlSVsgKKbMN8iInCdo',
            libraries: ["places","geometry"]
          }),
        GooglePlaceModule,
        MatGoogleMapsAutocompleteModule,
        AgmDirectionModule,

        //NGRX
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
          }),

        ToastrModule.forRoot(),

        AngularFireModule.initializeApp(environment.firebaseConfig),

        FontAwesomeModule
    ],
    providers : [

        UsuarioProfileService,
        //Interceptores
        {
            provide: HTTP_INTERCEPTORS,
            useClass : AuthInterceptor,
            multi : true
        },
        { provide: LOCALE_ID, useValue: 'es-MX' } ,
        { provide: MAT_DATE_LOCALE, useValue: 'es-MX' }



    ],
    bootstrap   : [
        AppComponent
    ],

})
export class AppModule
{
}
