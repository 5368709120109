
import { Filtro } from './filtro.model';
import { ProductoPaginador } from 'app/main/productos/producto/models/productoPaginador.model';



export interface ProductoAppState{
  producto ?: ProductoPaginador,
  filtro ?: Filtro,
}



export class ProductoApp implements ProductoAppState{
    constructor(
      public producto ?,
      public filtro ?,
    ){}
  }