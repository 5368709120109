import { Fruit } from '../crearContrato/crearContrato.component';

export class Contrato
{
    constructor(
        public _id ?: string,
        public numeroContrato ?: string,
        public password ?: string,
        public fechaInicio ?: Date,
        public fechaFin ?: Date,
        public importe ?: string,
        public descuento ?: string,
        public estatus ?: Fruit [],
        public ciudad ?: string,
        public zonaHoraria ?: number,
        public longitud ?: number,
        public latitud ?: number,
        public pdf ?: string,
        public nombreSolicitante ?: String,
        public apellidoSolicitante ?: String,
        public fechaNacimientoSolicitante ?: String,
        public telefono ?: string,
        public tipoContrato ?: string,
        public servicioUno ?: Date,
        public servicioDos ?: Date,
        public servicioTres ?: Date,
        public servicioUnocheck ?: boolean,
        public servicioDoscheck ?: boolean,
        public servicioTrescheck ?: boolean,
        public urlMaps ?: string,
        public partner ?: any,
        public owner ?: any,
        )
    {

    }

}
