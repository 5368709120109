

export class Departamento
{
    constructor(
        public _id ?: string,
        public torre ?: string,
        public departamento ?: string,
        public  amenidades ?: string,
        public  modificaciones ?: string,
        public  complejo ?: any,
        public  partner ?: any,
        public  estatus ?: any,
    ){}

}
