import * as fromAdministracionApp from './administracionApp.accions';
import { AdministracionApp, AdministracionAppState } from 'app/models/administracionApp.model';


const estadoInicial: AdministracionAppState = new AdministracionApp();


export function BusquedaReducer(state = estadoInicial,action:fromAdministracionApp.acciones):AdministracionAppState{
    switch(action.type){
        //   ADMINISTRACION ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_ADMINISTRACIONAPP:
            let nuevoApp = { ...action.administracionApp };
            localStorage.setItem('contratosApp', JSON.stringify(nuevoApp));
            return nuevoApp
        case fromAdministracionApp.QUIT_ADMINISTRACIONAPP:
            return new AdministracionApp();

        //   EMPRESA ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_EMPRESA:
            let nuevoEstadoEmpresa = { ...state };
            localStorage.setItem('empresa', JSON.stringify(nuevoEstadoEmpresa));
            nuevoEstadoEmpresa.empresas = {...action.empresa };
            return nuevoEstadoEmpresa;
        case fromAdministracionApp.QUIT_EMPRESA:
            let quitEmpresa = { ...state };
            quitEmpresa.empresas.paginador = null;
            localStorage.removeItem('empresa');
            return quitEmpresa;

        //   EMPRESA MODEL---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_EMPRESA_MODEL:
            let nuevoEstadoEmpresaModel = { ...state };
            localStorage.setItem('empresaModel', JSON.stringify(nuevoEstadoEmpresaModel));
            nuevoEstadoEmpresaModel.empresas.empresa = {...action.empresa };
            return nuevoEstadoEmpresaModel;
        case fromAdministracionApp.QUIT_EMPRESA_MODEL:
            let quitEmpresaModel = { ...state };
            quitEmpresaModel.empresas.empresa = null;
            localStorage.removeItem('empresaModel');
            return quitEmpresaModel;

        //   GRUPO ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_GRUPO:
            let nuevoEstadoGrupo = { ...state };
            localStorage.setItem('grupo', JSON.stringify(nuevoEstadoGrupo));
            nuevoEstadoGrupo.grupos = {...action.grupo };
            return nuevoEstadoGrupo;
        case fromAdministracionApp.QUIT_GRUPO:
            localStorage.removeItem('grupo');
            let quitGrupo = { ...state };
            quitGrupo.grupos.paginador = null;
            return quitGrupo;

        //   GRUPO MODEL---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_GRUPO_MODEL:
            let nuevoEstadoGrupoModel = { ...state };
            localStorage.setItem('grupoModel', JSON.stringify(nuevoEstadoGrupoModel));
            nuevoEstadoGrupoModel.grupos.grupo = {...action.grupo };
            return nuevoEstadoGrupoModel;
        case fromAdministracionApp.QUIT_GRUPO_MODEL:
            localStorage.removeItem('grupoModel');
            let quitGrupoModel = { ...state };
            quitGrupoModel.grupos.grupo = null;
            return quitGrupoModel;

       //   PARTNER ---------------------------------------------------------------------------------------------------------
       case fromAdministracionApp.SET_PARTNER:
        let nuevoEstadoPartner = { ...state };
            nuevoEstadoPartner.partner = { ...action.partner };
            localStorage.setItem('partner', JSON.stringify(nuevoEstadoPartner));
            return nuevoEstadoPartner;
        case fromAdministracionApp.QUIT_PARTNER:
            localStorage.removeItem('partner');
            let quitPartner = { ...state };
            quitPartner.partner.paginador = null;
            return quitPartner;

       //   PARTNER MODEL---------------------------------------------------------------------------------------------------------
       case fromAdministracionApp.SET_PARTNER_MODEL:
        let nuevoEstadoPartnerModel = { ...state };
        nuevoEstadoPartnerModel.partner.partner = { ...action.partner };
            localStorage.setItem('partnerModel', JSON.stringify(nuevoEstadoPartnerModel));
            return nuevoEstadoPartnerModel;
        case fromAdministracionApp.QUIT_PARTNER_MODEL:
            localStorage.removeItem('partnerModel');
            let quitPartnerModel = { ...state };
            quitPartnerModel.partner.partner = null;
            return quitPartnerModel;

        //   USUARIO ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_USUARIO:
            let nuevoEstadoUsuario = { ...state };
            nuevoEstadoUsuario.usuarios = { ...action.usuario };
            localStorage.setItem('usuario', JSON.stringify(nuevoEstadoUsuario));
            return nuevoEstadoUsuario;
        case fromAdministracionApp.QUIT_USUARIO:
            localStorage.removeItem('usuario');
            let quitUsuario = { ...state };
            quitUsuario.usuarios.paginador = null;
            return quitUsuario;

        //   USUARIO MODEL---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_USUARIO_MODEL:
            let nuevoEstadoUsuarioModel = { ...state };
            nuevoEstadoUsuarioModel.usuarios.usuario = { ...action.usuario };
            localStorage.setItem('usuarioModel', JSON.stringify(nuevoEstadoUsuarioModel));
            return nuevoEstadoUsuarioModel;
        case fromAdministracionApp.QUIT_USUARIO_MODEL:
            localStorage.removeItem('usuarioModel');
            let quitUsuarioModel = { ...state };
            quitUsuarioModel.usuarios.usuario = null;
            return quitUsuarioModel;

             //   CATEGORIA ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_CATEGORIA:
            let nuevoEstadoCategoria = { ...state };
            nuevoEstadoCategoria.categorias = { ...action.categoria };
            localStorage.setItem('categoria', JSON.stringify(nuevoEstadoCategoria));
            return nuevoEstadoCategoria;
        case fromAdministracionApp.QUIT_CATEGORIA:
            localStorage.removeItem('categoria');
            let quitCategoria = { ...state };
            quitCategoria.categorias.paginador = null;
            return quitCategoria;

             //   CATEGORIA MODEL---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_CATEGORIA_MODEL:
            let nuevoEstadoCategoriaModel = { ...state };
            nuevoEstadoCategoriaModel.categorias.categoria = { ...action.categoria };
            localStorage.setItem('categoriaModel', JSON.stringify(nuevoEstadoCategoriaModel));
            return nuevoEstadoCategoriaModel;
        case fromAdministracionApp.QUIT_CATEGORIA_MODEL:
            localStorage.removeItem('categoriaModel');
            let quitCategoriaModel = { ...state };
            quitCategoriaModel.categorias.categoria = null;
            return quitCategoriaModel;

             //   SUBCATEGORIA ---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_SUBCATEGORIA:
            let nuevoEstadoSubCategoria = { ...state };
            nuevoEstadoSubCategoria.subCategorias = { ...action.subCategoria };
            localStorage.setItem('subCategoria', JSON.stringify(nuevoEstadoSubCategoria));
            return nuevoEstadoSubCategoria;
        case fromAdministracionApp.QUIT_SUBCATEGORIA:
            localStorage.removeItem('subCategoria');
            let quitSubCategoria = { ...state };
            quitSubCategoria.subCategorias.paginador = null;
            return quitSubCategoria;

             //   SUBCATEGORIA MODEL---------------------------------------------------------------------------------------------------------
        case fromAdministracionApp.SET_SUBCATEGORIA_MODEL:
            let nuevoEstadoSubCategoriaModel = { ...state };
            nuevoEstadoSubCategoriaModel.subCategorias.subCategoria = { ...action.subCategoria };
            localStorage.setItem('subCategoriaModel', JSON.stringify(nuevoEstadoSubCategoriaModel));
            return nuevoEstadoSubCategoriaModel;
        case fromAdministracionApp.QUIT_SUBCATEGORIA_MODEL:
            localStorage.removeItem('subCategoriaModel');
            let quitSubCategoriaModel = { ...state };
            quitSubCategoriaModel.subCategorias.subCategoria = null;
            return quitSubCategoriaModel;


        default:
            return state;
    }
}
