import { Contrato } from './../../../main/contrato/model/contrato.model';
import {Action} from '@ngrx/store';
import { ContratoPaginador } from 'app/main/contrato/model/contratoPaginador.model';
import { ContratoApp } from 'app/models/contratoApp.model';
import { Filtro } from 'app/models/filtro.model';
import { PaginadorTabla } from 'app/models/paginador.model';

export const SET_CONTRATO_SERVICIOS_PAGINADOR = '[ContratoServiciosPaginador] Set ContratoServiciosPaginador';
export const QUIT_CONTRATO_SERVICIOS_PAGINADOR = '[ContratoServiciosPaginador] Quit ContratoServiciosPaginador';

export const SET_CONTRATO_SERVICIOS = '[ContratoServicios] Set ContratoServicios';
export const QUIT_CONTRATO_SERVICIOS = '[ContratoServicios] Quit ContratoServicios';

export const SET_CONTRATO_SERVICIOS_APP = '[ContratoServiciosApp] Set ContratoServiciosApp';
export const QUIT_CONTRATO_SERVICIOS_APP = '[ContratoServiciosApp] Quit ContratoServiciosApp';

export const SET_FILTRO_CONTRATO_SERVICIOS = '[FiltroContratoServicios] Set FiltroContratoServicios';
export const QUIT_FILTRO_CONTRATO_SERVICIOS = '[FiltroContratoServicios] Quit FiltroContratoServicios';


//   CONTRATOSERVICIOSPAGINADOR ---------------------------------------------------------------------------------------------------------
export class SetContratoServiciosPaginadorAction implements Action{
    readonly type = SET_CONTRATO_SERVICIOS_PAGINADOR;
    constructor (public contratoPaginador : ContratoPaginador){}
}
export class QuitContratoServiciosPaginadorAction implements Action{
    readonly type = QUIT_CONTRATO_SERVICIOS_PAGINADOR;
    constructor (){}
}

//   CONTRATOSERVICIOS ---------------------------------------------------------------------------------------------------------
export class SetContratoServiciosAction implements Action{
    readonly type = SET_CONTRATO_SERVICIOS;
    constructor (public contrato : Contrato){}
}
export class QuitContratoServiciosAction implements Action{
    readonly type = QUIT_CONTRATO_SERVICIOS;
    constructor (){}
}

//   CONTRATOSERVICIOSAPP ---------------------------------------------------------------------------------------------------------
export class SetContratoServiciosAppAction implements Action{
    readonly type = SET_CONTRATO_SERVICIOS_APP;
    constructor (public contratoApp : ContratoApp){}
}
export class QuitContratoServiciosAppAction implements Action{
    readonly type = QUIT_CONTRATO_SERVICIOS_APP;
    constructor (){}
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroContratoServiciosAction implements Action{
    readonly type = SET_FILTRO_CONTRATO_SERVICIOS;
    constructor (public filtro : Filtro){}
}
export class QuitFiltroContratoServiciosAction implements Action{
    readonly type = QUIT_FILTRO_CONTRATO_SERVICIOS;
    constructor (){}
}

export type acciones = SetContratoServiciosPaginadorAction  |  QuitContratoServiciosPaginadorAction | SetContratoServiciosAction
                      | QuitContratoServiciosAction | SetContratoServiciosAppAction | QuitContratoServiciosAppAction
                      | SetFiltroContratoServiciosAction | QuitFiltroContratoServiciosAction;
