import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { AppState } from 'app/app.reducer';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Filtro } from 'app/models/filtro.model';
import { UserPaginador } from './estudiantes/model/userPaginador.model';
import { SetEstudiantesControlEscolarAction, QuitEstudiantesControlEscolarAction, SetFiltroControlEscolarAction, SetControlEscolarAppAction, SetMaestrosControlEscolarAction, QuitMaestrosControlEscolarAction, SetGruposControlEscolarAction, QuitGruposControlEscolarAction } from 'app/services/reducers/controlEscolarApp/controlEscolarApp.accions';
import { ControlEscolarApp } from 'app/models/controlEscolarApp.model';
import { GrupoPaginador } from './grupo/models/grupoPaginador.model';


@Injectable({
  providedIn: 'root'
})
export class ControlEscolarService implements Resolve<any> {


    controlEscolarApp : ControlEscolarApp = new ControlEscolarApp();
    controlEscolaSuscriber : Subscription;
    filtro : Filtro = new Filtro();

    constructor( private store: Store<AppState>) {

   }

  crearSubcriber (){
    return this.store.select('controlEscolarApp');
  }

  cambiarAlumno ( userPaginador: UserPaginador ){
      this.store.dispatch(new SetEstudiantesControlEscolarAction(userPaginador));
  }
  quitarAlumno ( userPaginador: UserPaginador ){
    this.store.dispatch(new QuitEstudiantesControlEscolarAction(userPaginador));
  }
  cambiarMaestro ( userPaginador: UserPaginador ){
    this.store.dispatch(new SetMaestrosControlEscolarAction(userPaginador));
  }
  quitarMaestro ( userPaginador: UserPaginador ){
    this.store.dispatch(new QuitMaestrosControlEscolarAction(userPaginador));
  }
  cambiarGrupo ( grupoPaginador: GrupoPaginador ){
    this.store.dispatch(new SetGruposControlEscolarAction(grupoPaginador));
  }
  quitarGrupo ( grupoPaginador: GrupoPaginador ){
    this.store.dispatch(new QuitGruposControlEscolarAction(grupoPaginador));
  }




  cambiarFiltroControlEscolar ( filtro : Filtro ){
    this.store.dispatch(new SetFiltroControlEscolarAction(filtro));
  }


  setControlEscolarApp ( controlEscolarApp : ControlEscolarApp ){
    this.store.dispatch(new SetControlEscolarAppAction(controlEscolarApp));
  }


   eliminarStore(){
        this.controlEscolaSuscriber.unsubscribe();
   }

   cargarStorage(): Promise<any> {

    return new Promise ((resolve, reject ) =>{
      let controlEscolarApp: ControlEscolarApp = new ControlEscolarApp();
      if (localStorage.getItem('controlEscolarApp')) {
        controlEscolarApp = JSON.parse(localStorage.getItem('controlEscolarApp'));
        //console.log('controlEscolarApp:' + controlEscolarApp);
      }
     /*if (!productoInversion.filtro){
        productoInversion.filtro = new Filtro ();
      }*/
      //console.log(controlEscolarApp);
      this.setControlEscolarApp(controlEscolarApp);
      resolve(controlEscolarApp);
    });



  }





  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {
          Promise.all([
              this.cargarStorage(), //  carga el ultimo estado del modulo de contratos
          ]).then(
              ([controlEscolarApp]) => {
                  this.controlEscolarApp = controlEscolarApp;
                  resolve();
              },
              reject
          );
      });
  }





}
