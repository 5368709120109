
import { Empresa } from '../../empresa/models/empresa.model';
import { Grupo } from '../../grupo/models/grupo.model';

export class Usuario
{
  constructor(
    public _id?: string,
    public email?: string,
    public password?: string,

    public token?:string,
    public nombre?: string,
    public surname?: string,

    public role?: string,
    public empresa?:Empresa,
    public empresaId?:string,
    public fechaNacimiento?:string,
    public wa?:string,
    public tel?:string,
    public cel?:string,
    public grupo ?:Grupo,
    public img?: string,
    public google?: boolean,

    public address_components?: any,
    public partner ?: string,
    public ciudad ?: string,
    public zonaHoraria ?: number,
    public longitud ?: number,
    public latitud ?: number,
    public sale ?: string,
    public owner?: string,
    public direccion ?: string,

    public rfc ?: string,
    public comisionVendedor ?: number,

    public urlMaps ?: string,
    public numeroCliente ?: string,
    public numeroVendedor ?: string,
    public telefonoReferenciaUno ?: string,
    public telefonoReferenciaDos ?: string,
    public nombreReferenciaUno ?: string,
    public nombreReferenciaDos ?: string,
    public observaciones ?: string,
    public sexo ?: string,
    public telefonoCelular ?: string,
    public estatus ?: any,
    public uidFirebase ?: any,
    public clavePublicaConekta ?: string,
    public clavePrivadaConekta ?: string,
  )
  {

  }

}
