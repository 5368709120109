import { filter } from 'rxjs/operators';
import { PaginadorTabla } from './../../../models/paginador.model';
import * as fromAplicacion from './aplicacion.accions';
import { Aplicacion } from 'app/models/aplicacion.model';
import { Usuario } from 'app/main/administracion/usuarios/models/usuario.model';
import { Partner } from 'app/main/administracion/partner/models/partner.model';
import { Filtro } from 'app/models/filtro.model';


export interface AplicacionState{
    partner  ?: Partner,
    usuario ?: Usuario,
    paginador ?: PaginadorTabla,
    filtro ?: Filtro,
    current?: any,
    lastEdit?: String

}
const estadoInicial: AplicacionState={
    partner  : null,
    usuario : null,
    paginador: new PaginadorTabla(),
    filtro: new Filtro(),
    current: null,
    lastEdit: null
}
export function AplicacionReducer(state = estadoInicial,action:fromAplicacion.acciones):AplicacionState{
    //console.log(action);
    switch(action.type){
        //   APP USUARIO ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION_USUARIO:
            let nuevoUsuario = { ...state };
            nuevoUsuario.usuario = { ...action.usuario };
            localStorage.setItem('aplicacion', JSON.stringify(nuevoUsuario));
            return nuevoUsuario;
        case fromAplicacion.QUIT_APLICACION_USUARIO:
            let quitUsuario = { ...state };
            quitUsuario.usuario = null;
            return quitUsuario;
        //   APP PARTNER ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION_PARTNER:
            let nuevopartner = { ...state };
            nuevopartner.partner = { ...action.partner };
            localStorage.setItem('aplicacion', JSON.stringify(nuevopartner));
            return nuevopartner
        case fromAplicacion.QUIT_APLICACION_PARTNER:
            let quitpartner = { ...state };
            quitpartner.partner = null;
            return quitpartner;
        //   APP PAGINADOR ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION_PAGINADOR:
            let paginador = { ...state };
            paginador.paginador = { ...action.paginador };
            localStorage.setItem('aplicacion', JSON.stringify(paginador));
            return paginador;
        case fromAplicacion.QUIT_APLICACION_PAGINADOR:
            let quitPaginador = { ...state };
            quitPaginador.paginador = null;
            return quitPaginador;
        //   APP FILTER ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION_FILTRO:
            let currentState = { ...state };
            currentState.filtro = { ...action.filtro };
            currentState.paginador.desde = 0;

            localStorage.setItem('aplicacion', JSON.stringify(currentState));
            return currentState;
        case fromAplicacion.QUIT_APLICACION_FILTRO:
            let quitCurrentState = { ...state };
            quitCurrentState.filtro = null;
            return quitCurrentState;
        //   APP MODEL EDIT ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION_CURRENT_MODEL_EDIT:
            let setCurrentStateModel = { ...state };
            setCurrentStateModel.current = { ...action.model };
            localStorage.setItem('aplicacion', JSON.stringify(setCurrentStateModel));
            return setCurrentStateModel;
        case fromAplicacion.QUIT_APLICACION_CURRENT_MODEL_EDIT:
            let quitCurrentStateModel = { ...state };
            quitCurrentStateModel.lastEdit = state.current?._id;
            quitCurrentStateModel.current = null;
            return quitCurrentStateModel;
        //   APP ---------------------------------------------------------------------------------------------------------
        case fromAplicacion.SET_APLICACION:
            let nuevoApp ={... action.app};
            localStorage.setItem('aplicacion', JSON.stringify(nuevoApp));
            return nuevoApp
        case fromAplicacion.QUIT_APLICACION:
          return new Aplicacion();
        default:
            return state;
    }
}
