import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse, HttpClient
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {tap} from 'rxjs/operators';
import {AppState} from 'app/app.reducer';
import {Store} from '@ngrx/store';
import {SetUserToken, QuitUserToken} from 'app/main/administracion/usuarios/reducers/token.accions';
import {UsuarioProfileService} from '../../main/administracion/usuarios/services/usuarioProfile.service';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {URL_SERVICIOS} from '../../config/config';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: string;

  constructor(public usuarioProfileService: UsuarioProfileService,
              public store: Store<AppState>,
              public http: HttpClient,
              public router: Router
  ) {
    this.token = localStorage.getItem('token');
  }



  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');

    //console.log(req.urlWithParams.endsWith('login') + 'i>' + req.urlWithParams);

    if (!token) {
      return next.handle(req);
    }



    if (req.headers.get(environment.tokenName)) {

      return next.handle(req);
    }


    const headers = req.clone({

      headers: req.headers.set(environment.tokenName, token)
    });
    return next.handle(headers).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
        },
        error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 ) {
              this.router.navigate(["/pages/notfound"]);
              //this.usuarioProfileService.logOut();
            }
          }
        }
      ),
    );
  }

  manejaError(error: HttpErrorResponse) {

    return throwError('Error Personalizado');
  }
}
