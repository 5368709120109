import {Action} from '@ngrx/store';
import { ContratosApp } from 'app/models/contratosApp.model';
import { ContratoPaginador } from 'app/main/contratosv2/contrato/model/contratoPagina.model';
import { Filtro } from 'app/models/filtro.model';
import { IngresoPaginador } from 'app/main/contratosv2/ingresos/models/ingresoPaginador.model';
import { EgresoPaginador } from 'app/main/contratosv2/gastos/models/egresoPaginador.model';
import { CasaPaginador } from 'app/main/contratosv2/casas/model/casasPaginador.model';
import { DepartamentoPaginador } from 'app/main/contratosv2/departamentos/model/departamentoPaginador.model';
import { PredioPaginador } from 'app/main/contratosv2/predios/model/predioPaginador.model';
import { Contrato } from 'app/main/contratosv2/contrato/model/contrato.model';
import { Ingreso } from 'app/main/contratosv2/ingresos/models/ingresos.model';
import { Egreso } from 'app/main/contratosv2/gastos/models/egresos.model';
import { Complejo } from 'app/main/contratosv2/complejos/model/complejo.model';
import { Departamento } from 'app/main/contratosv2/departamentos/model/departamento.model';
import { Casa } from 'app/main/contratosv2/casas/model/casas.model';
import { Predio } from 'app/main/contratosv2/predios/model/predio.model';

export const SET_CONTRATO = '[Contrato] Set Contrato';
export const QUIT_CONTRATO = '[Contrato] Quit Contrato';

export const SET_CONTRATO_MODEL = '[ContratoModel] Set ContratoModel';
export const QUIT_CONTRATO_MODEL = '[ContratoModel] Quit ContratoModel';

export const SET_INGRESO = '[Ingreso] Set Ingreso';
export const QUIT_INGRESO = '[Ingreso] Quit Ingreso';

export const SET_INGRESO_MODEL = '[IngresoModel] Set IngresoModel';
export const QUIT_INGRESO_MODEL = '[IngresoModel] Quit IngresoModel';

export const SET_EGRESO = '[Egreso] Set Egreso';
export const QUIT_EGRESO = '[Egreso] Quit Egreso';

export const SET_EGRESO_MODEL = '[EgresoModel] Set EgresoModel';
export const QUIT_EGRESO_MODEL = '[EgresoModel] Quit EgresoModel';

export const SET_COMPLEJO = '[Complejo] Set Complejo';
export const QUIT_COMPLEJO = '[Complejo] Quit Complejo';

export const SET_COMPLEJO_MODEL = '[ComplejoModel] Set ComplejoModel';
export const QUIT_COMPLEJO_MODEL = '[ComplejoModel] Quit ComplejoModel';

export const SET_DEPARTAMENTO = '[Departamento] Set Departamento';
export const QUIT_DEPARTAMENTO = '[Departamento] Quit Departamento';

export const SET_DEPARTAMENTO_MODEL = '[DepartamentoModel] Set DepartamentoModel';
export const QUIT_DEPARTAMENTO_MODEL = '[DepartamentoModel] Quit DepartamentoModel';

export const SET_CASA = '[Casa] Set Casa';
export const QUIT_CASA = '[Casa] Quit Casa';

export const SET_CASA_MODEL = '[CasaModel] Set CasaModel';
export const QUIT_CASA_MODEL = '[CasaModel] Quit CasaModel';

export const SET_PREDIO = '[Predio] Set Predio';
export const QUIT_PREDIO = '[Predio] Quit Predio';

export const SET_PREDIO_MODEL = '[PredioModel] Set PredioModel';
export const QUIT_PREDIO_MODEL = '[PredioModel] Quit PredioModel';

export const SET_CONTRATOAPP = '[ContratoApp] Set ContratoApp';
export const QUIT_CONTRATOAPP = '[ContratApp] Quit ContratoApp';

export const SET_FILTROCONTRATOS = '[FiltroContratos] Set FiltroContratos';
export const QUIT_FILTROCONTRATOS = '[FiltroContratos] Quit FiltroContratos';


//   CONTRATO ---------------------------------------------------------------------------------------------------------
export class SetContratoAction implements Action{
    readonly type = SET_CONTRATO;
    constructor (public contrato : ContratoPaginador){}
}
export class QuitContratoAction implements Action{
    readonly type = QUIT_CONTRATO;
    constructor (){}
}

//   CONTRATO MODEL---------------------------------------------------------------------------------------------------------
export class SetContratoModelAction implements Action{
    readonly type = SET_CONTRATO_MODEL;
    constructor (public contrato : Contrato){}
}
export class QuitContratoModelAction implements Action{
    readonly type = QUIT_CONTRATO_MODEL;
    constructor (){}
}

//   INGRESO ---------------------------------------------------------------------------------------------------------
export class SetIngresoAction implements Action{
    readonly type = SET_INGRESO;
    constructor (public ingreso : IngresoPaginador){}
}
export class QuitIngresoAppAction implements Action{
    readonly type = QUIT_INGRESO;
    constructor (){}
}

//   INGRESO MODEL---------------------------------------------------------------------------------------------------------
export class SetIngresoModelAction implements Action{
    readonly type = SET_INGRESO_MODEL;
    constructor (public ingreso : Ingreso){}
}
export class QuitIngresoAppModelAction implements Action{
    readonly type = QUIT_INGRESO_MODEL;
    constructor (){}
}

//   EGRESO ---------------------------------------------------------------------------------------------------------
export class SetEgresoAction implements Action{
    readonly type = SET_EGRESO;
    constructor (public egreso : EgresoPaginador){}
}

export class QuitEgresoAppAction implements Action{
    readonly type = QUIT_EGRESO;
    constructor (){}
}

//   EGRESO MODEL---------------------------------------------------------------------------------------------------------
export class SetEgresoModelAction implements Action{
    readonly type = SET_EGRESO_MODEL;
    constructor (public egreso : Egreso){}
}

export class QuitEgresoAppModelAction implements Action{
    readonly type = QUIT_EGRESO_MODEL;
    constructor (){}
}

//   COMPLEJO ---------------------------------------------------------------------------------------------------------
export class SetComplejoAction implements Action{
    readonly type = SET_COMPLEJO;
    constructor (public complejo : Complejo){}
}
export class QuitComplejoAction implements Action{
    readonly type = QUIT_COMPLEJO;
    constructor (){}
}

//   COMPLEJO MODEL---------------------------------------------------------------------------------------------------------
export class SetComplejoModelAction implements Action{
    readonly type = SET_COMPLEJO_MODEL;
    constructor (public complejo : Complejo){}
}
export class QuitComplejoModelAction implements Action{
    readonly type = QUIT_COMPLEJO_MODEL;
    constructor (){}
}

//   DEPARTAMENTO ---------------------------------------------------------------------------------------------------------
export class SetDepartamentoAction implements Action{
    readonly type = SET_DEPARTAMENTO;
    constructor (public departamento : DepartamentoPaginador){}
}
export class QuitDepartamentoAction implements Action{
    readonly type = QUIT_DEPARTAMENTO;
    constructor (){}
}

//   DEPARTAMENTO MODEL---------------------------------------------------------------------------------------------------------
export class SetDepartamentoModelAction implements Action{
    readonly type = SET_DEPARTAMENTO_MODEL;
    constructor (public departamento : Departamento){}
}
export class QuitDepartamentoModelAction implements Action{
    readonly type = QUIT_DEPARTAMENTO_MODEL;
    constructor (){}
}

//   CASA ---------------------------------------------------------------------------------------------------------
export class SetCasaAction implements Action{
    readonly type = SET_CASA;
    constructor (public casa : CasaPaginador){}
}
export class QuitCasaAction implements Action{
    readonly type = QUIT_CASA;
    constructor (){}
}

//   CASA MODEL---------------------------------------------------------------------------------------------------------
export class SetCasaModelAction implements Action{
    readonly type = SET_CASA_MODEL;
    constructor (public casa : Casa){}
}
export class QuitCasaModelAction implements Action{
    readonly type = QUIT_CASA_MODEL;
    constructor (){}
}

//   PREDIO ---------------------------------------------------------------------------------------------------------
export class SetPredioAction implements Action{
    readonly type = SET_PREDIO;
    constructor (public predio : PredioPaginador){}
}
export class QuitPredioAction implements Action{
    readonly type = QUIT_PREDIO;
    constructor (){}
}

//   PREDIO MODEL---------------------------------------------------------------------------------------------------------
export class SetPredioModelAction implements Action{
    readonly type = SET_PREDIO_MODEL;
    constructor (public predio : Predio){}
}
export class QuitPredioModelAction implements Action{
    readonly type = QUIT_PREDIO_MODEL;
    constructor (){}
}

//   CONTRATO ---------------------------------------------------------------------------------------------------------
export class SetContratoAppAction implements Action{
    readonly type = SET_CONTRATOAPP;
    constructor (public contratosApp : ContratosApp){}
}
export class QuitContratoAppAction implements Action{
    readonly type = QUIT_CONTRATOAPP;
    constructor (){}
}

//   FIlTRO ---------------------------------------------------------------------------------------------------------
export class SetFiltroContratosAction implements Action{
    readonly type = SET_FILTROCONTRATOS;
    constructor (public filtro : Filtro){}
}
export class QuitFiltroContratosAction implements Action{
    readonly type = QUIT_FILTROCONTRATOS;
    constructor (){}
}

export type acciones = SetContratoAction  |  QuitContratoAction | SetIngresoAction |  QuitIngresoAppAction
| SetEgresoAction |  QuitEgresoAppAction | SetComplejoAction | QuitComplejoAction | SetDepartamentoAction
| QuitDepartamentoAction | SetCasaAction | QuitCasaAction | SetPredioAction | QuitPredioAction
| SetContratoAppAction | QuitContratoAppAction | SetFiltroContratosAction | QuitFiltroContratosAction
| SetContratoModelAction | QuitContratoModelAction | SetIngresoModelAction | QuitIngresoAppModelAction
| SetEgresoModelAction | QuitEgresoAppModelAction | SetComplejoModelAction | QuitComplejoModelAction
| SetDepartamentoModelAction | QuitDepartamentoModelAction | SetCasaModelAction | QuitCasaModelAction
| SetPredioModelAction | QuitPredioModelAction;
