import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.reducer';
import {URL_SERVICIOS} from 'app/config/config';
import {ImagenService} from '../../../../services/imagen/imagen.service';
import {ToastrService} from 'ngx-toastr';
import {Partner} from 'app/main/administracion/partner/models/partner.model';
import { PaginadorTabla } from 'app/models/paginador.model';
import { Observable } from 'rxjs';
import { PartnerPaginador } from '../models/partnerPaginador.model';
import { AdministracionAppService } from '../../administracion.service';

@Injectable({
    providedIn: 'root'
})
export class PartnerService implements Resolve<any>{

    partner: Partner = new Partner();
    token: string;
    routeParams: any;
    partnerPaginador: PartnerPaginador = new PartnerPaginador();
    constructor(public http: HttpClient,
                public router: Router,
                public store: Store<AppState>,
                public imagenService: ImagenService,
                public toasterService: ToastrService,
                public administracionService: AdministracionAppService) {

    }
    obtenerSociosParaCombo(payload) {
        //console.log('service combo socios');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        const url = URL_SERVICIOS + '/sociosParaCombo';
        return this.http.post(url, payload, {headers});
    }
    obtenerPartnerSinPaginacion() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        const url = URL_SERVICIOS + '/partner';
        return this.http.get(url, {headers});
    }

    obtenerPartner(paginador : PaginadorTabla) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        const url = URL_SERVICIOS + '/partners';
        return this.http.post(url,paginador, {headers});
    }


    obtenerPartnerId(id: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        const url = URL_SERVICIOS + '/partner/' + id;
        return this.http.get(url, {headers});
    }


    eliminarPartner(id: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        const url = URL_SERVICIOS + '/partner/' + id;
        return this.http.delete(url, {headers});
    }

    agregarPartner(partner: Partner) {
        //console.log(partner);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
        });
        let url = URL_SERVICIOS + '/partner';
        if (partner._id) {
            //console.log(partner);
            url += '/' + partner._id;
            return this.http.put(url, partner, {headers});
        } else {
            return this.http.post(url, partner, {headers});
        }
    }

    cambiarImagen(archivo: File, id: string) {
        this.imagenService.subirArchivo(archivo, 'partners', id)
            .then((resp: any) => {
                //console.log(resp);
                this.partner = resp.partner;
                this.partner.img = resp.partner.img;
                this.toasterService.success('La Imagen se ha guardado correctamente ', 'Imagen');

                let tmp = {...this.partnerPaginador}
                tmp.partner = this.partner
                this.administracionService.cambiarPartner(tmp);

            })
            .catch(resp => {
                //console.log(resp);
                this.toasterService.error('La Imagen NO se ha guardado correctamente ', 'Imagen');
            });
    }

    cualPartnerEs() {
        const url = URL_SERVICIOS + '/partner-cfg';
        return this.http.get(url);
    }




    /**
   * Get contrato
   *
   * @returns {Promise<any>}
   */
  getPartner(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('token')
      });
      if('new' === this.routeParams.idPartner){
        resolve(this.partner = new Partner());
      }

      this.http.get(URL_SERVICIOS + '/partner/' + this.routeParams.idPartner, {headers})
        .subscribe((response: any) => {
            //console.log(response);
          this.partner = response.partner;
          this.administracionService.cambiarPartnerModel(response.partner);
          resolve(response.contrato);
        }, reject);
    });
  }



  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getPartner(),

      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
}







