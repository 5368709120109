import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { AppState } from 'app/app.reducer';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProductoInversionPaginador } from './product-investment/models/product-investmentPaginador.model';
import {  SetProductoAppInversionAction  ,  QuitProcuctoAppInversionAction , SetProductoInversionAction , QuitProductoInversionAction 
          , SetFiltroProductoInversionAction , QuitFiltroProductoInversionAction , SetContratoInversionAction  ,  QuitContratoInversionAction 
          , SetLeadInversionAction , QuitLeadInversionAction , SetInteresVariableInversionAction , QuitInteresVariableInversionAction 
          , SetCommissionInversionT1Action , QuitCommissionInversionT1Action , SetCommissionInversionT2Action , QuitCommissionInversionT2Action 
          , SetCommissionInversionT3Action , QuitCommissionInversionT3Action , SetCommissionInversionT4Action , QuitCommissionInversionT4Action 
          , SetProductoInversionModelAction , QuitProductoInversionModelAction , SetContratoInversionModelAction , QuitContratoInversionModelAction 
          , SetLeadInversionModelAction , QuitLeadInversionModelAction , SetCommissionInversionT1ModelAction , QuitCommissionInversionT1ModelAction 
          , SetCommissionInversionT2ModelAction , QuitCommissionInversionT2ModelAction , SetCommissionInversionT3ModelAction , QuitCommissionInversionT3ModelAction 
          , SetCommissionInversionT4ModelAction , QuitCommissionInversionT4ModelAction
} from 'app/services/reducers/inversionApp/inversionApp.accions';
import { Filtro } from 'app/models/filtro.model';
import { ProductoInversionApp } from 'app/models/productosInversion.model';
import { ContratoInversionPaginador } from './contrato/model/contratoInversionPagina.model';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { LeadPaginador } from './leads/models/leadPaginador.model';
import { InteresVariableInversion } from './interesVariable/models/interesVariable.model';
import { CommissionInversionPaginador } from './commission/models/commisionInversionPaginador';
import { ProductInvestment } from './product-investment/models/product-investment.model';
import { Lead } from './leads/models/lead.model';
import { CommissionInversion } from './commission/models/commisionInversion.model';
import { ContratoInversion } from './contrato/model/contratoInversion.model';


@Injectable({
  providedIn: 'root'
})
export class InversionesService implements Resolve<any> {

  //API_KEY = '8efa33af43db89934960bc4f23c42272cc8bfd26';
  productoInversion : ProductoInversionApp = new ProductoInversionApp();
  productoInversionSubscriber : Subscription;
  filtro : Filtro = new Filtro();

  constructor( private store: Store<AppState>, private httpClient:HttpClient) {}

  crearSubcriber (){
    return this.store.select('productoInversionApp');
  }

  cambiarProductoInversion ( productoInversionPaginador: ProductoInversionPaginador ){
    this.store.dispatch(new SetProductoInversionAction(productoInversionPaginador));
  }

  quitarProductoInversion (){
    this.store.dispatch(new QuitProductoInversionAction());
  }

  cambiarProductoInversionModel ( productoInversion: ProductInvestment ){
    this.store.dispatch(new SetProductoInversionModelAction(productoInversion));
  }

  quitarProductoInversionModel (){
    this.store.dispatch(new QuitProductoInversionModelAction());
  }

  cambiarLead ( leadPaginador: LeadPaginador ){
    this.store.dispatch(new SetLeadInversionAction(leadPaginador));
  }

  quitarLead (){
    this.store.dispatch(new QuitLeadInversionAction());
  }

  cambiarLeadModel ( lead: Lead ){
    this.store.dispatch(new SetLeadInversionModelAction(lead));
  }

  quitarLeadModel (){
    this.store.dispatch(new QuitLeadInversionModelAction());
  }

  // COMISION TRIMESTRE 1
  cambiarCommissionT1 ( commissionPaginador: CommissionInversionPaginador ){
    this.store.dispatch(new SetCommissionInversionT1Action(commissionPaginador));
  }

  quitarCommissionT1 (){
    this.store.dispatch(new QuitCommissionInversionT1Action());
  }

  cambiarCommissionT1Model ( commission: CommissionInversion ){
    this.store.dispatch(new SetCommissionInversionT1ModelAction(commission));
  }

  quitarCommissionT1Model (){
    this.store.dispatch(new QuitCommissionInversionT1ModelAction());
  }

  // COMISION TRIMESTRE 2
  cambiarCommissionT2 ( commissionPaginador: CommissionInversionPaginador ){
    this.store.dispatch(new SetCommissionInversionT2Action(commissionPaginador));
  }

  quitarCommissionT2 (){
    this.store.dispatch(new QuitCommissionInversionT2Action());
  }

  cambiarCommissionT2Model ( commission: CommissionInversion ){
    this.store.dispatch(new SetCommissionInversionT2ModelAction(commission));
  }

  quitarCommissionT2Model (){
    this.store.dispatch(new QuitCommissionInversionT2ModelAction());
  }

  // COMISION TRIMESTRE 3
  cambiarCommissionT3 ( commissionPaginador: CommissionInversionPaginador ){
    this.store.dispatch(new SetCommissionInversionT3Action(commissionPaginador));
  }

  quitarCommissionT3 (){
    this.store.dispatch(new QuitCommissionInversionT3Action());
  }

  cambiarCommissionT3Model ( commission: CommissionInversion ){
    this.store.dispatch(new SetCommissionInversionT3ModelAction(commission));
  }

  quitarCommissionT3Model (){
    this.store.dispatch(new QuitCommissionInversionT3ModelAction());
  }

  // COMISION TRIMESTRE 4
  cambiarCommissionT4 ( commissionPaginador: CommissionInversionPaginador ){
    this.store.dispatch(new SetCommissionInversionT4Action(commissionPaginador));
  }

  quitarCommissionT4 (){
    this.store.dispatch(new QuitCommissionInversionT4Action());
  }

  cambiarCommissionT4Model ( commission: CommissionInversion ){
    this.store.dispatch(new SetCommissionInversionT4ModelAction(commission));
  }

  quitarCommissionT4Model (){
    this.store.dispatch(new QuitCommissionInversionT4ModelAction());
  }

  cambiarInteresVariable ( interesVariable: InteresVariableInversion ){
    this.store.dispatch(new SetInteresVariableInversionAction(interesVariable));
  }

  quitarInteresVariable (){
    this.store.dispatch(new QuitInteresVariableInversionAction());
  }

  cambiarContratoInversion ( contratoInversionPaginador: ContratoInversionPaginador ){
    this.store.dispatch(new SetContratoInversionAction(contratoInversionPaginador));
  }

  quitarContratoInversion ( ){
    this.store.dispatch(new QuitContratoInversionAction());
  }

  cambiarContratoInversionModel ( contratoInversion: ContratoInversion ){
    this.store.dispatch(new SetContratoInversionModelAction(contratoInversion));
  }

  quitarContratoInversionModel ( ){
    this.store.dispatch(new QuitContratoInversionModelAction());
  }

  cambiarFiltro ( filtro : Filtro ){
    this.store.dispatch(new SetFiltroProductoInversionAction(filtro));
  }
  
  quitarFiltro ( ){
    this.store.dispatch(new QuitFiltroProductoInversionAction());
  }

  setProductosInversionApp ( productoInversionApp : ProductoInversionApp ){
    this.store.dispatch(new SetProductoAppInversionAction(productoInversionApp));
  }
  
  quitarProductosInversionApp ( ){
    this.store.dispatch(new QuitProcuctoAppInversionAction());
  }

  eliminarStore(){
    this.productoInversionSubscriber.unsubscribe();
  }

  cargarStorage(): Promise<any> {
    return new Promise ((resolve, reject ) =>{
      let productoInversion: ProductoInversionApp = new ProductoInversionApp();
      if (localStorage.getItem('contratosApp')) {
        productoInversion = JSON.parse(localStorage.getItem('productoInversionApp'));
      }
      this.setProductosInversionApp(productoInversion);
      resolve(productoInversion);
    });
  }

  sincronizaUsuariosClientify(){
    const url = environment.urlServices + '/clientify/sync-users';
    return this.httpClient.get(url );
  }

  sincronizaContratosClientes(){
    const url = environment.urlServices + '/clientify/sync-contratos';
    return this.httpClient.get(url);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
      return new Promise<void>((resolve, reject) => {
          Promise.all([
              this.cargarStorage(), //  carga el ultimo estado del modulo de contratos
          ]).then(
              ([productoInversion]) => {
                  this.productoInversion = productoInversion;
                  resolve();
              },
              reject
          );
      });
  }

}
