import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Observable, Subscription } from 'rxjs';

import { ConfiguracionesApp } from 'app/models/configuracionesApp.model';
import { ConfiguracionPaginador } from './models/configuracionPaginador.model';
import { SetConfiguracionAction, 
         QuitConfiguracionAction, 
         SetConfiguracionesAppAction,
         QuitConfiguracionesAppAction,
         SetConfiguracionModelAction,
         QuitConfiguracionModelAction
} from '../.././services/reducers/configuraciones/configuracion.accions';
import { Configuracion } from './models/configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesAppService {

  configuracionesApp : ConfiguracionesApp = new ConfiguracionesApp();
  configuracionesSubscriber : Subscription;

  constructor(
    public store: Store<AppState>,
  ) { }


  crearSubcriber (){
    return this.store.select('configuracionesApp');
  }

  cambiarConfiguracionesApp ( configuracionPaginador: ConfiguracionPaginador ){
    this.store.dispatch(new SetConfiguracionAction(configuracionPaginador));
  }

  quitarConfiguracionesApp (){
    this.store.dispatch(new QuitConfiguracionAction());
  }

  cambiarConfiguracionesAppModel ( configuracion: Configuracion ){
    this.store.dispatch(new SetConfiguracionModelAction(configuracion));
  }

  quitarConfiguracionesAppModel (){
    this.store.dispatch(new QuitConfiguracionModelAction());
  }

  setConfiguracionesApp ( configuracionesApp : ConfiguracionesApp ){
    this.store.dispatch(new SetConfiguracionesAppAction(configuracionesApp));
  }

  quitConfiguracionesApp (){
    this.store.dispatch(new QuitConfiguracionesAppAction());
  }


  eliminarStore(){
    this.configuracionesSubscriber.unsubscribe();
  }

  cargarStorage(): Promise<any> {

    return new Promise ((resolve, reject ) =>{
      let configuracionesAppp: ConfiguracionesApp = new ConfiguracionesApp();
      if (localStorage.getItem('configuracionesApp')) {
        configuracionesAppp = JSON.parse(localStorage.getItem('configuracionesApp'));
        //console.log('configuracionesApp:' + configuracionesAppp);
      }

      //console.log(configuracionesAppp);
      this.setConfiguracionesApp(configuracionesAppp);
      resolve(configuracionesAppp);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
      return new Promise<void>((resolve, reject) => {
          Promise.all([
              this.cargarStorage(),
          ]).then(
              ([configuracionesApp]) => {
                  this.configuracionesApp = configuracionesApp;
                  resolve();
              },
              reject
          );
      });
  }

}
