
import { Filtro } from './filtro.model';
import { UserPaginador } from 'app/main/controlEscolar/estudiantes/model/userPaginador.model';
import { GrupoPaginador } from 'app/main/controlEscolar/grupo/models/grupoPaginador.model';
import { NotificacionPaginador } from 'app/main/controlEscolar/notificaciones/models/notificacionPaginador.model';
import { ProgramaPaginador } from 'app/main/controlEscolar/programas/modelos/programPaginador.model';




export interface ControlEscolarAppState{
  userEstudiante ?: UserPaginador,
  userMaestro ?: UserPaginador,
  grupo ?: GrupoPaginador,
  notificacion ?: NotificacionPaginador,
  programa ?: ProgramaPaginador,
  filtro ?: Filtro,
}



export class ControlEscolarApp implements ControlEscolarAppState{
    constructor(
      public userEstudiante ?,
      public userMaestro ?,
      public grupo ?,
      public notificacion ?,
      public filtro ?,
      public programa ?,
    ){}
  }