

export class Empresa
{
    constructor(
        public _id?: string,
        public org?: string,
        public dir?: string,
        public facebook?: string,
        public app?: string,
        public www?: string,
        public tel?: string,
        public cel?: string,
        public wa?: string,
        public img?: string,
        public owner?: any,
        public partner?: any,
        public sale?: any,
        public host?: any,
        public urlMaps?: string,
        public lat?: number,
        public long?: number,
        ){}
}
