import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICIOS } from '../config/config';


@Pipe({
    name: 'imagen'
  })
  export class ImagenPipe implements PipeTransform {

    transform(img: string, tipo: string = 'usuario'): any {
      let url = URL_SERVICIOS + '/img';
      if (!img){
        return url + '/usuarios/xxx';
      }
      if (img.indexOf('https') >= 0)
      {
        return img;
      }
      switch (tipo)
      {
        case 'usuario':
           url += '/usuarios/' + img;
           break;
        case 'empresas':
              url += '/empresas/' + img;
              break;
        case 'tarjeta-avatar':
          url += '/tarjeta-avatar/' + img;
          break;
        case 'tarjeta-logo':
          url += '/tarjeta-logo/' + img;
          break;
          case 'tarjeta-icono':
            url += '/tarjeta-icono/' + img;
            break;
        case 'estadios':
           url += '/estadios/' + img;
           break;
        case 'quinielas':
          url += '/quinielas/' + img;
          break;
        case 'partners':
            url += '/partners/' + img;
        break;
        case 'productos':
          url += '/productos/' + img;
      break;
        default:
          url += '/usuarios/xxx';
        }
      return url;
    }
  }

