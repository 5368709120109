export class Contrato
{
    constructor(
        public _id ?: string,
        public numeroContrato ?: Date,
        public fechaContrato ?: string,
        public cliente ?: any,
        public vendedor ?: any,
        public complejo ?: any,
        public numeroMensualidades ?: number,
        public estatus ?: string,
        public posecion ?: string,
        public fechaApartado ?: Date,
        public fechaFirma ?: Date,
        public fechaEntrea ?: Date,
        public fechaDePrimerPago ?: Date,
        public observaciones ?: string,
        public mensualidad ?: number,
        public categoria ?: string,
        public enganche ?: string,
        public pagos ?: string,
        public owner ?: string,
        )
    {

    }

}
