import { Filtro } from './filtro.model';
import { ContratoPaginador } from 'app/main/contratosv2/contrato/model/contratoPagina.model';
import { IngresoPaginador } from 'app/main/contratosv2/ingresos/models/ingresoPaginador.model';
import { EgresoPaginador } from 'app/main/contratosv2/gastos/models/egresoPaginador.model';
import { CasaPaginador } from 'app/main/contratosv2/casas/model/casasPaginador.model';
import { DepartamentoPaginador } from 'app/main/contratosv2/departamentos/model/departamentoPaginador.model';
import { PredioPaginador } from 'app/main/contratosv2/predios/model/predioPaginador.model';
import { Complejo } from 'app/main/contratosv2/complejos/model/complejo.model';

export interface ContratoAppState{
  complejo?: Complejo,
  contrato ?: ContratoPaginador,
  ingreso ?: IngresoPaginador,
  egreso ?: EgresoPaginador,
  casa ?: CasaPaginador,
  departamento ?: DepartamentoPaginador,
  predio ?: PredioPaginador,
  filtro ?: Filtro,
}

export class ContratosApp implements ContratoAppState{
    constructor(
      public contrato ?:ContratoPaginador,
      public ingreso ?,
      public egreso ?,
      public complejo ?,
      public departamento ?: DepartamentoPaginador,
      public casa ?: CasaPaginador,
      public predio ?: PredioPaginador,
      public filtro ?
    ){}
  }
